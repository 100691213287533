import React from 'react';
import Ad from '@ifeng-fe/ui_pc_ad';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import House from './house';
import HeadLines from './headLines';
// import News from './news';
import Finance from './finance';
import Ent from './ent';
import Sport from './sport';
import Mil from './mil';
import Video from './video';
import Auto from './auto';
import History from './history';
import Novel from './novel';
import Tech from './tech';
import Pingce from './pingce';
import Society from './society';
import Lottery from './lottery';
import Fashion from './fashion';
import Health from './health';
import NoSSR from 'react-no-ssr';
import Buddhism from './buddhism';
import Picture from './picture';
import Fm from './fm';
import Wutong from './wutong';
import Game from './game';
import Food from './food';
import Wine from './wine';
import Vip from './vip';
import Travel from './travel';

class Channel extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        isSecretCallup: PropTypes.bool,
    };

    // 静默拉起方法
    addSecretAttribute = data => {
        if (!data) return data;
        if (Array.isArray(data)) {
            return data.map(item => ({
                secretCallUp: item.articleTag !== '翻阅小说',
                newstype: item.type,
                kind: 'inter_sflahuo01',
                noPrefixKind: true,
                wapactCount: {
                    value: 'direct',
                },
                ...item,
            }));
        } else {
            return {
                secretCallUp: data.articleTag !== '翻阅小说',
                newstype: data.type,
                kind: 'inter_sflahuo01',
                noPrefixKind: true,
                wapactCount: {
                    value: 'direct',
                },
                ...data,
            };
        }
    };

    render() {
        const { content, isSecretCallup } = this.props;
        let {
            stickFeed,
            headLinesMd,
            headLinesAllFeedData,
            // newsMd,
            // newsSlide,
            // newsAllFeedData,
            financeMd,
            financeSlide,
            financeRecommendFeed,
            entMd,
            entSlide,
            entAllFeedData,
            milMd,
            milSlide,
            milFeed,
            sportMd,
            sportSlide,
            sportFeed,
            videoMd,
            videoSlide,
            videoFeed,
            autoMd,
            autoSlide,
            autoFeed,
            autoFourPic,
            historyMd,
            historySlide,
            historyRecommendFeed,
            novelMd,
            novelSlide,
            novelFeed,
            techMd,
            techSlide,
            techFeed,
            pingceMd,
            pingceSlide,
            pingceFeed,
            pingceAd,
            societyMd,
            societySlide,
            societyFeed,
            fashionMd,
            fashionSlide,
            fashionFeed,
            healthMd,
            healthSlide,
            healthRecommendFeed,
            buddhismMd,
            buddhismSlide,
            buddhismFeed,
            pictureMd,
            pictureSlide,
            pictureFeed,
            fmMd,
            fmSlide,
            fmRecommendFeed,
            wutongMd,
            wutongSlide,
            wutongRecommendFeed,
            gameMd,
            gameSlide,
            gameFeed,
            foodMd,
            foodSlide,
            foodFeed,
            wineMd,
            wineSlide,
            wineFeed,
            headLinesAd,
            // newsAd,
            financeAd,
            entAd,
            milAd,
            sportAd,
            videoAd,
            autoAd,
            houseAd,
            historyAd,
            novelAd,
            techAd,
            societyAd,
            fashionAd,
            healthAd,
            buddhismAd,
            pictureAd,
            fmAd,
            wutongAd,
            gameAd,
            foodAd,
            wineAd,
            firstScreenAd,
            localStationData,
            travelMd,
            travelSlide,
            travelFeed,
            travelAd,
            vipMd,
            vipSlide,
            vipFeed,
            vipAd,
            newEraFeed,
        } = content;
        // 模块信息流加载是否异步
        const isAsync = true;

        if (isSecretCallup) {
            stickFeed = this.addSecretAttribute(stickFeed);
            newEraFeed = this.addSecretAttribute(newEraFeed);
            headLinesAllFeedData = this.addSecretAttribute(headLinesAllFeedData);
            entAllFeedData = this.addSecretAttribute(entAllFeedData);
            // newsSlide = this.addSecretAttribute(newsSlide);
            // newsAllFeedData = this.addSecretAttribute(newsAllFeedData);
            financeSlide = this.addSecretAttribute(financeSlide);
            financeRecommendFeed = this.addSecretAttribute(financeRecommendFeed);
            entSlide = this.addSecretAttribute(entSlide);
            entAllFeedData = this.addSecretAttribute(entAllFeedData);
            fashionSlide = this.addSecretAttribute(fashionSlide);
            fashionFeed = this.addSecretAttribute(fashionFeed);
            milSlide = this.addSecretAttribute(milSlide);
            milFeed = this.addSecretAttribute(milFeed);
        }

        const headLinesData = {
            headLinesMd,
            stickFeed,
            headLinesAllFeedData,
            newEraFeed,
            localStationData,
            headLinesAd,
        };
        // const newData = {
        //     newsMd,
        //     newsSlide,
        //     newsAllFeedData,
        //     newsAd,
        // };
        const financeData = {
            financeMd,
            financeSlide,
            financeRecommendFeed,
            financeAd,
        };
        const entData = {
            entMd,
            entSlide,
            entAllFeedData,
            entAd,
        };
        const milData = {
            milMd,
            milSlide,
            milFeed,
            milAd,
        };
        const sportData = {
            sportMd,
            sportSlide,
            sportFeed,
            sportAd,
        };
        const videoData = {
            videoMd,
            videoSlide,
            videoFeed,
            videoAd,
        };
        const autoData = {
            autoMd,
            autoSlide,
            autoFeed,
            autoFourPic,
            autoAd,
            isAsync,
        };
        const historyData = {
            historyMd,
            historySlide,
            historyRecommendFeed,
            historyAd,
        };
        const novelData = {
            novelMd,
            novelSlide,
            novelFeed,
            novelAd,
        };
        const techData = {
            techMd,
            techSlide,
            techFeed,
            techAd,
            isAsync,
        };
        const pingceData = {
            pingceMd,
            pingceSlide,
            pingceFeed,
            pingceAd,
            isAsync,
        };
        const societyData = {
            societyMd,
            societySlide,
            societyFeed,
            societyAd,
            isAsync,
        };
        const fashionData = {
            fashionMd,
            fashionSlide,
            fashionFeed,
            fashionAd,
            isAsync,
        };
        const healthData = {
            healthMd,
            healthSlide,
            healthRecommendFeed,
            healthAd,
        };
        const buddhismData = {
            buddhismMd,
            buddhismSlide,
            buddhismFeed,
            buddhismAd,
        };
        const pictureData = {
            pictureMd,
            pictureSlide,
            pictureFeed,
            pictureAd,
            isAsync,
        };
        const fmData = {
            fmMd,
            fmSlide,
            fmRecommendFeed,
            fmAd,
        };
        const wutongData = {
            wutongMd,
            wutongSlide,
            wutongRecommendFeed,
            wutongAd,
        };
        const gameData = {
            gameMd,
            gameSlide,
            gameFeed,
            gameAd,
        };
        const foodData = {
            foodMd,
            foodSlide,
            foodFeed,
            foodAd,
            isAsync,
        };
        const wineData = {
            wineMd,
            wineSlide,
            wineFeed,
            wineAd,
            isAsync,
        };
        const travelData = {
            travelMd,
            travelSlide,
            travelFeed,
            travelAd,
        };
        const vipData = {
            vipMd,
            vipSlide,
            vipFeed,
            vipAd,
        };

        return (
            <React.Fragment>
                <NoSSR>
                    <HeadLines content={headLinesData} />
                </NoSSR>
                <NoSSR>
                    <Ad content={firstScreenAd} />
                </NoSSR>
                <Finance content={financeData} />
                <Wutong content={wutongData} />
                <Ent content={entData} />
                <Fashion content={fashionData} />
                <Mil content={milData} />
                <Sport content={sportData} />
                <Video content={videoData} />
                <Novel content={novelData} />
                <Auto content={autoData} />
                <House ad={houseAd} isAsync={isAsync} />
                <Tech content={techData} />
                <Pingce content={pingceData} />
                <History content={historyData} />
                <Society content={societyData} />
                <Travel content={travelData} />
                <Food content={foodData} />
                <Health content={healthData} />
                <Buddhism content={buddhismData} />
                <Picture content={pictureData} />
                <Fm content={fmData} />
                <Game content={gameData} />
                <Vip content={vipData} />
                <Wine content={wineData} />
                {/* <Lottery /> */}
            </React.Fragment>
        );
    }
}

export default errorBoundary(Channel);
