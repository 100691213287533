import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { formatImage } from '@ifeng-fe/public_method';
import { cookie } from '@ifeng-fe/ui_base';
import { getHomeLocalStationData, getRecommendDataList } from '../../../../../services/api';
import { newStickFeedDataHandle } from '../utils';
import styles from './index.css';
import Module from '../../module';

class HeadLines extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        localStationData: [],
        localStationUrl: '',
        localStationName: '',
    };

    componentDidMount() {
        this.localStationInfo();
    }
    localStationInfo() {
        const provinceId = cookie.get('prov');
        const cityId = cookie.get('city');
        const { localStationData } = this.props.content;
        let isCity = false;
        let isProvince = false;

        localStationData.forEach(item => {
            if (item.number.toString() === provinceId.toString()) {
                if (item.city && item.city.length > 0) {
                    item.city.forEach(items => {
                        if (cityId.toString() === items.cityNumber && items.isCityShow) {
                            isCity = true;
                            this.getLocalStationApi(items.cityId, items.cityUrl, items.cityName, items.isCityUcms);
                        }
                    });
                } else if (item.isOpen && item.isShow) {
                    isProvince = true;
                    this.getLocalStationApi(item.id, item.url, item.name, item.isUcms);
                }

                if (!isCity && !isProvince && item.isOpen && item.isShow) {
                    this.getLocalStationApi(item.id, item.url, item.name, item.isUcms);
                }
            }
        });
    }
    async getLocalStationApi(id, url, name, isUcms) {
        let dataCb = '';

        if (isUcms) {
            dataCb = await getRecommendDataList(id, 38);
        } else {
            dataCb = await getHomeLocalStationData(id);
        }

        if (dataCb && dataCb.length > 0) {
            let localStationData = dataCb;

            if (!isUcms) {
                localStationData = this.formatData(dataCb);
            }

            this.setState({
                localStationData,
                localStationUrl: url,
                localStationId: id,
                localStationName: `${name}要闻`,
            });
        }
    }

    formatData(data) {
        const formatData =
            data &&
            data.map((item, index) => {
                let heatMarkWork = '';
                let page = 0;
                let thumbnails = '';
                const hasVideoIcon = item.dataType === 'video' ? 1 : 0;

                if (item.dataType === 'topic' || item.dataType === 'special') {
                    heatMarkWork = '专题';
                } else if (item.dataType === 'video' || item.dataType === 'phvideo') {
                    heatMarkWork = '视频';
                } else if (item.dataType === 'slide' && item.picNums) {
                    page = item.picNums;
                } else if (item.iconType) {
                    heatMarkWork = item.iconType;
                }

                if (item.i_thumbnail) {
                    thumbnails = {
                        image: [
                            {
                                bytes: 277,
                                width: 230,
                                url: formatImage(item.i_thumbnail, 230, 152),
                                height: 152,
                            },
                        ],
                    };
                }

                return {
                    id: index,
                    type: 'article',
                    title: item.title || '',
                    url: item.pageUrl || '',
                    thumbnails,
                    heatMark: heatMarkWork,
                    hasVideoIcon,
                    page,
                };
            });

        return formatData;
    }

    getRandFeed(feedList = [], len = 0, allFeedData) {
        const result = [];
        const feedListClone = feedList;
        const allFeedBase62 = allFeedData.map(item => item.base62Id);

        if (feedList.length < 5) {
            return feedList;
        }

        for (let i = 0; i < feedList.length; ++i) {
            const random = Math.floor(Math.random() * feedListClone.length);

            if ((feedListClone[random].base62Id && allFeedBase62.includes(feedListClone[random].base62Id)) || result.length >= len) {
                continue;
            }
            result.push(feedListClone[random]);
            feedListClone.splice(random, 1);
        }

        return result;
    }

    feedRepeatHandle = (stickBase62IdList = [], feedList = []) => {
        const newFeedList = feedList.filter(item => !stickBase62IdList.includes(item.base62Id));

        return newFeedList;
    };

    render() {
        const { content } = this.props;
        const { localStationData, localStationUrl, localStationName } = this.state;
        let { stickFeed } = content;
        const { headLinesMd, headLinesAllFeedData, headLinesAd, newEraFeed } = content;
        const normalTabsData = headLinesMd;
        const isStickBottom = true;
        const stickFeedChipConfig = {
            id: '75230',
            type: 'recommend',
            title: '要闻区置顶数据',
            groupName: 'content',
            translate: 'handleHomeStickInfo',
        };
        // const newsStreamChipConfig = {
        //     id: '255935',
        //     type: 'recommend',
        //     title: '要闻区数据',
        //     groupName: 'content',
        //     translate: 'handleHomeHeadLinesInfo',
        // };

        if (normalTabsData) {
            normalTabsData.chipConfig = {
                id: '50172',
                type: 'struct',
                title: '要闻区子导航数据',
                groupName: 'content',
                translate: 'handleHomeHeadLinesMdInfo',
            };
        }
        normalTabsData.localStationInfo = {
            url: localStationUrl,
            name: localStationName,
            defaultWord: '切换城市',
            switchWord: '返回要闻',
        };
        const totalNum = 5;

        // 置顶超过2条数据特殊逻辑判断
        if (stickFeed && stickFeed.length <= totalNum) {
            const allFeedData = stickFeed.concat(headLinesAllFeedData);
            const newEraRandomFeed = this.getRandFeed(newEraFeed, totalNum - stickFeed.length, allFeedData);

            if (newEraRandomFeed) {
                stickFeed = stickFeed.concat(newEraRandomFeed);
            }
        }
        let newStickFeedData = newStickFeedDataHandle(stickFeed);

        newStickFeedData = newStickFeedData.map((item, index) => {
            // const noStickText = index > 2;

            return {
                ...item,
                noStickText: true,
            };
        });
        const stickBase62IdList = newStickFeedData.map(item => item.base62Id);
        const newsStreamData = this.feedRepeatHandle(stickBase62IdList, headLinesAllFeedData);
        const moduleData = {
            normalTabsData,
            feedAd: headLinesAd,
            stickFeedChipConfig,
            stickFeed: newStickFeedData,
            isStickBottom,
            newsStreamData,
            localStationData,
            showNum: 22,
            stepNum: 10,
            countNum: 52,
        };

        return <section className={styles.headLines}>{moduleData.newsStreamData && <Module content={moduleData} />}</section>;
    }
}

export default errorBoundary(HeadLines);
