import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { getDynamicDataList } from '../../../../../services/api';
import Observer from '@researchgate/react-intersection-observer';
import styles from './index.css';
import Module from '../../module';

class Fashion extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        dataListArr: [],
    };

    async getDataListFn() {
        try {
            const dataListArr = await getDynamicDataList('75394');

            this.setState({
                dataListArr,
            });
        } catch (e) {
            console.error(e);
        }
    }

    handleChange = (event, unobserve) => {
        const { content } = this.props;
        const { isAsync } = content;

        if (event.isIntersecting) {
            unobserve();
            if (isAsync) {
                this.getDataListFn();
            }
        }
    };

    render() {
        const { content } = this.props;
        const { dataListArr } = this.state;
        const { fashionMd, fashionSlide, fashionFeed, fashionAd, isAsync } = content;

        const normalTabsData = fashionMd;
        const viewMoreLinkData = (fashionMd && fashionMd.moreTh) || '';
        const newsStreamData = isAsync ? dataListArr : fashionFeed;
        const mdBannerData = fashionSlide;

        if (normalTabsData) {
            normalTabsData.chipConfig = {
                id: '50156',
                type: 'struct',
                title: '时尚子导航数据',
                groupName: 'content',
                translate: 'handleHomeFinanceMdInfo',
            };
        }
        if (mdBannerData) {
            mdBannerData.chipConfig = {
                id: '75190',
                type: 'recommend',
                title: '时尚焦点图数据',
                groupName: 'content',
                translate: 'handleHomeFinanceSlideInfo',
            };
        }
        const moduleData = {
            normalTabsData,
            feedAd: fashionAd,
            mdBannerData,
            newsStreamData,
            viewMoreLinkData,
        };

        return (
            <Observer onChange={this.handleChange}>
                <section className={styles.fashion}>
                    <Module content={moduleData} />
                </section>
            </Observer>
        );
    }
}

export default errorBoundary(Fashion);
