import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { getSelectedPoolDataList } from '../../../../../services/api';
import Observer from '@researchgate/react-intersection-observer';
import styles from './index.css';
import Module from '../../module';

class Sport extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        dataListArr: [],
    };

    async getDataListFn() {
        try {
            const dataListArr = await getSelectedPoolDataList('20011');

            this.setState({
                dataListArr,
            });
        } catch (e) {
            console.error(e);
        }
    }

    handleChange = (event, unobserve) => {
        const { content } = this.props;
        const { isAsync } = content;

        if (event.isIntersecting) {
            unobserve();
            if (isAsync) {
                this.getDataListFn();
            }
        }
    };

    render() {
        const { content } = this.props;
        const { dataListArr } = this.state;
        const { sportMd, sportSlide, sportFeed, sportAd, isAsync } = content;

        const normalTabsData = sportMd;
        const viewMoreLinkData = (sportMd && sportMd.moreTh) || '';
        const newsStreamData = isAsync ? dataListArr : sportFeed;
        const mdBannerData = sportSlide;

        if (normalTabsData) {
            normalTabsData.chipConfig = {
                id: '50148',
                type: 'struct',
                title: '体育子导航数据',
                groupName: 'content',
                translate: 'handleHomeSportMdInfo',
            };
        }
        if (mdBannerData) {
            mdBannerData.chipConfig = {
                id: '75246',
                type: 'recommend',
                title: '体育焦点图数据',
                groupName: 'content',
                translate: 'handleHomeSportSlideInfo',
            };
        }
        const newsStreamChipConfig = {
            id: '75248',
            type: 'recommend',
            title: '体育信息流数据',
            groupName: 'content',
            translate: 'handleHomeSportFeedInfo',
        };
        const moduleData = {
            normalTabsData,
            feedAd: sportAd,
            mdBannerData,
            newsStreamChipConfig,
            newsStreamData,
            viewMoreLinkData,
        };

        return (
            <Observer onChange={this.handleChange}>
                <section className={styles.sport}>
                    <Module content={moduleData} />
                </section>
            </Observer>
        );
    }
}

export default errorBoundary(Sport);
