import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { formatImageWebpAuto } from '@ifeng-fe/public_method';
import { getDynamicDataList } from '../../../../../services/api';
import Observer from '@researchgate/react-intersection-observer';
import styles from './index.css';
import Module from '../../module';

class Video extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        dataListArr: [],
    };

    addSecretAttribute = data => {
        if (Array.isArray(data)) {
            return data.map(item => ({
                secretCallUp: true,
                newstype: item.type,
                kind: 'inter_sflahuo01',
                noPrefixKind: true,
                wapactCount: {
                    value: 'direct',
                },
                ...item,
            }));
        } else {
            return {
                secretCallUp: true,
                newstype: data.type,
                kind: 'inter_sflahuo01',
                noPrefixKind: true,
                wapactCount: {
                    value: 'direct',
                },
                ...data,
            };
        }
    };

    async getDataListFn() {
        try {
            const dataList = await getDynamicDataList('210439');
            // let feedListArr = [];
            // let bannerData = [];

            // if (dataList.bodyList && dataList.bodyList.length > 0) {
            //     feedListArr = dataList.bodyList.map((item, index) => ({
            //         id: item.infoId || index,
            //         commentUrl: item.infoId || '',
            //         title: item.title,
            //         base62Id: (item.infoId && item.infoId.replace('ucms_', '')) || undefined,
            //         url: item.memberItem && item.memberItem.pcUrl,
            //         thumbnails: item.imageList &&
            //             item.imageList[0] &&
            //             item.imageList[0].image && {
            //                 image: [
            //                     {
            //                         bytes: 277,
            //                         width: 340,
            //                         url: formatImage(item.imageList[0].image, 340, 188),
            //                         height: 188,
            //                     },
            //                 ],
            //             },
            //         articleTag: '视频',
            //         articleTagColor: 10,
            //         type: 'phvideo',
            //     }));
            // }

            this.setState({
                dataListArr: dataList,
            });
        } catch (e) {
            console.error(e);
        }
    }

    handleChange = (event, unobserve) => {
        const { content } = this.props;
        const { isAsync } = content;

        if (event.isIntersecting) {
            unobserve();
            if (isAsync) {
                this.getDataListFn();
            }
        }
    };

    render() {
        const { content } = this.props;
        const { dataListArr } = this.state;
        const { videoMd, videoSlide, videoFeed, videoAd, isAsync } = content;

        const normalTabsData = videoMd;
        const viewMoreLinkData = (videoMd && videoMd.moreTh) || '';
        const newsStreamData = isAsync ? dataListArr : videoFeed;
        const indexSlidesData = videoSlide.map(item => ({
            ...item,
            src: formatImageWebpAuto(item.src, 698, 350),
        }));
        const indexSlidesConfig = {
            id: '75167',
            type: 'recommend',
            title: '视频焦点图数据',
            groupName: 'content',
            translate: 'handleHomeVideoSlideInfo',
        };

        if (normalTabsData) {
            normalTabsData.chipConfig = {
                id: '50149',
                type: 'struct',
                title: '视频子导航数据',
                groupName: 'content',
                translate: 'handleHomeVideoMdInfo',
            };
        }
        const moduleData = {
            normalTabsData,
            feedAd: videoAd,
            indexSlidesData,
            indexSlidesConfig,
            newsStreamData,
            viewMoreLinkData,
        };

        return (
            <Observer onChange={this.handleChange}>
                <section className={styles.video}>
                    <Module content={moduleData} />
                </section>
            </Observer>
        );
    }
}

export default errorBoundary(Video);
