import React from 'react';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';

class Lottery extends React.PureComponent {
    state = {
        moduleHeight: 100,
    };
    componentDidMount() {
        if (typeof window === 'object') {
            window.addEventListener(
                'message',
                event => {
                    if (event.data) {
                        this.setState({
                            moduleHeight: event.data,
                        });
                    }
                },
                false,
            );
        }
    }
    render() {
        const { moduleHeight } = this.state;
        const style = {
            height: `${moduleHeight}px`,
        };

        return (
            <section className={styles.lottery}>
                <iframe
                    src="https://8.ifeng.com/ifnewIndex.html"
                    id="home_cp_iframe"
                    width="100%"
                    frameBorder="no"
                    border="0"
                    style={style}
                />
            </section>
        );
    }
}

export default errorBoundary(Lottery);
