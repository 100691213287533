import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Hongbao from '@ifeng-fe/three_terminal/es/mobile/hongbao/hongbao';
import Chip from 'Chip';

class Redbag extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;

        return (
            <Chip title="红包" type="struct" id="50189" groupName="红包" content={content}>
                <Hongbao tjKey={content ? content.indexCode : undefined} />
            </Chip>
        );
    }
}

export default errorBoundary(Redbag);
