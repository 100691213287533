import { cookie } from '@ifeng-fe/ui_base';

const UA = (typeof navigator === 'object' && navigator.userAgent.toLowerCase()) || '';
const isSafairBrowser = () => {
    const otherBrowserList = [
        'qqbrowser',
        'ucbrowser',
        'micromessenger',
        'baidubrowser',
        'baiduspider',
        'crios',
        'chrome',
        'sougoumobile',
        'fingerbrowser',
        'fxios',
        'mxios',
        'qihoobrowser',
        'lbbrowser',
    ];
    let isOtherBrowser = false;

    if (UA.match(/iphone/gi) && UA.match(/safari/gi)) {
        otherBrowserList.forEach(item => {
            if (UA.includes(item) && !isOtherBrowser) {
                isOtherBrowser = true;
            }
        });
        if (isOtherBrowser) {
            return false;
        }

        return true;
    }

    return false;
};

const isSupportIOSBrowser = () => {
    const browserList = ['baiduboxapp', 'crios', 'haopassion', 'sogoumobilebrowser'];
    let flag = false;

    if (UA.match(/iphone/gi)) {
        browserList.forEach(item => {
            if (UA.includes(item) && !flag) {
                flag = true;
            }
        });
    }

    // 欧朋浏览器
    if (UA.length <= 120) {
        flag = true;
    }

    return flag;
};

const isAndroidChrome = () => {
    if (UA.includes('android') && UA.length < 130 && UA.includes('chrome')) {
        return true;
    }

    return false;
};

const hasSecretCallup = () => {
    const userPV1Val = cookie.get('userPV1');
    const userPV2Val = cookie.get('userPV2');

    if (!userPV1Val && userPV2Val) {
        cookie.del('userPV2');
    }

    if (userPV1Val) {
        if (userPV2Val) {
            cookie.set('userPV2', parseInt(userPV2Val) + 1, '', '', 12 * 60 * 60 * 1000);
        } else {
            cookie.set('userPV2', 1, '', '', 12 * 60 * 60 * 1000);
        }
    }

    if (!userPV1Val && (isSafairBrowser() || isAndroidChrome() || isSupportIOSBrowser())) {
        cookie.set('userPV1', 1, '', '', 12 * 60 * 60 * 1000);

        return true;
    }

    return false;
};

export { hasSecretCallup };
