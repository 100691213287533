import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { getDynamicDataList } from '../../../../../services/api';
import Observer from '@researchgate/react-intersection-observer';
import styles from './index.css';
import Module from '../../module';

class Auto extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        dataListArr: [],
    };

    async getDataListFn() {
        try {
            const dataListArr = await getDynamicDataList('10-614-');

            this.setState({
                dataListArr,
            });
        } catch (e) {
            console.error(e);
        }
    }

    handleChange = (event, unobserve) => {
        const { content } = this.props;
        const { isAsync } = content;

        if (event.isIntersecting) {
            unobserve();
            if (isAsync) {
                this.getDataListFn();
            }
        }
    };

    render() {
        const { content } = this.props;
        const { dataListArr } = this.state;
        const { autoMd, autoSlide, autoFeed, autoFourPic, autoAd, isAsync } = content;

        const normalTabsData = autoMd;
        const viewMoreLinkData = (autoMd && autoMd.moreTh) || '';
        const newsStreamData = isAsync ? dataListArr : autoFeed;
        const mdBannerData = autoSlide;
        const fourPicData = autoFourPic;

        if (normalTabsData) {
            normalTabsData.chipConfig = {
                id: '50150',
                type: 'struct',
                title: '汽车子导航数据',
                groupName: 'content',
                translate: 'handleHomeAutoMdInfo',
            };
        }
        if (mdBannerData) {
            mdBannerData.chipConfig = {
                id: '75133',
                type: 'recommend',
                title: '汽车焦点图数据',
                groupName: 'content',
                translate: 'handleHomeAutoSlideInfo',
            };
        }
        const fourPicChipConfig = {
            id: '50151',
            type: 'struct',
            title: '汽车四图数据',
            groupName: 'content',
            translate: 'handleHomeAutoFourPicInfo',
        };
        const moduleData = {
            normalTabsData,
            feedAd: autoAd,
            mdBannerData,
            fourPicChipConfig,
            fourPicData,
            newsStreamData,
            viewMoreLinkData,
        };

        return (
            <Observer onChange={this.handleChange}>
                <section className={styles.auto}>
                    <Module content={moduleData} />
                </section>
            </Observer>
        );
    }
}

export default errorBoundary(Auto);
