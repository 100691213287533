import { jsonp } from '@ifeng-fe/ui_base';
import { formatImage, formatUrl } from '@ifeng-fe/public_method';
import { getSkey, handleNewstime, isArray, formatPlayTimes, formatVideoDuration } from './utils';
import md5 from 'md5';
// apiUrl为webpack注入的全局变量
/* eslint-disable no-undef */
let apiBaseUrl = apiUrl;
/* eslint-enable no-undef */

const getWeatherData = async city => {
    const data = await jsonp(`https://api.iclient.ifeng.com/newH5Weather?key=weather&value=${city}`);
    let result = [];

    if (data && data.data) {
        result = data.data;
    }

    return result;
};

const getKeywordData = async () => {
    const data = await jsonp('https://api.m.sm.cn/rest?method=tools.hot&size=0&from=wm818445');
    let result = [];

    if (data && data.length !== 0) {
        result = data;
    }
    /* eslint-enable no-undef */
    if (typeof window === 'object' && /\.shank\.ifeng\.com/.test(window.location.hostname)) {
        apiBaseUrl = '/api';
    }

    return result;
};

const createJsonpCallbackName = (str, num) => {
    num = num ? num : 0;
    let jsonpCallbackName = `_${md5(`${str}_${num}`)}`;

    /* eslint-disable */
    while (typeof window === 'object' && window[jsonpCallbackName]) {
        /* eslint-enable */
        num++;
        jsonpCallbackName = `_${md5(`${str}_${num}`)}`;
    }

    return jsonpCallbackName;
};

/* 公共 */

// 获取自选股数据
const getMyStockData = async num => {
    return await jsonp('//apiapp.finance.ifeng.com/mystock/get', {
        data: { num },
        jsonpCallback: createJsonpCallbackName('getMyStockData'),
        cache: false,
    });
};

// 获取股票数据
const getStockData = async codeList => {
    const callback = createJsonpCallbackName('getFinanceData');

    return await jsonp('//hq.finance.ifeng.com/q.php', {
        data: {
            l: codeList.join(','),
            f: 'json',
            e: `${callback}(json_q)`,
        },
        jsonpCallback: callback,
        cache: false,
    });
};

// 查询股票，资金，证券等数据
const getFinanceData = async (type, str) => {
    const callback = createJsonpCallbackName('getFinanceData');

    return await jsonp('//app.finance.ifeng.com/hq/suggest_v2.php', {
        data: {
            t: type,
            q: str,
            cb: `${callback}(suggest_json)`,
        },
        jsonpCallback: callback,
        cache: false,
    });
};

// 获取文章评论数
const getCommentCount = async url => {
    return await jsonp('//comment.ifeng.com/get.php', {
        data: {
            job: 4,
            format: 'js',
            callback: 'getAllComment1',
            docurl: url.join('|'),
        },
        jsonCallback: 'getAllComment1',
        cache: false,
    });
};

/* 财首 */

// 获取 7 * 24 数据
const getTopicData = async () => {
    return await jsonp('//api3.finance.ifeng.com/live/getnew', {
        data: {
            level: 1,
            dist: 1,
            cb: 'setNewCont',
        },
        jsonp: 'cb',
        jsonpCallback: 'setNewCont',
        cache: false,
    });
};

// 获取资金流向数据
const getFundsFlowData = async () => {
    return await jsonp('//i.finance.ifeng.com/moneyflow/flow/So', {
        jsonpCallback: 'getFundsFlowData',
        cache: false,
    });
};

// 根据自媒体账号 id 获取自媒体账号头像
const getwemediaEAccountImg = async wemediaEAccountId => {
    const data = await jsonp(
        `${apiBaseUrl}/finance/index/getwemediaEAccountImg/${wemediaEAccountId}/getwemediaEAccountImg${wemediaEAccountId}`,
        {
            jsonpCallback: `getwemediaEAccountImg${wemediaEAccountId}`,
            cache: false,
        },
    );

    let result = {};
    
    if (data.code === 0) {
        result = {
            img: data.data.headImage && data.data.headImage !== '' ? formatImage(data.data.headImage, 50, 50) : '',
            weMediaName: data.data.weMediaName ? data.data.weMediaName : '',
            description: data.data.description ? data.data.description : '',
        };
    }

    return result;
};
const getHouseData = async () => {
    const data = await jsonp('https://ihouse.ifeng.com/appifeng/IfengWapIndex?_cb=getHouseData', {
        jsonpCallback: 'getHouseData',
        cache: false,
    });
    let result = [];

    if (data && data.data && data.data.length !== 0) {
        result = data.data;
    }

    return result;
};

const getHomeLocalStationData = async id => {
    const data1 = await jsonp(`https://ifashion.ifeng.com/new/biztjw/${id}_0/getFlagData.shtml`, {
        jsonpCallback: 'getbizNewsDatacallback',
        cache: false,
    });
    const data2 = await jsonp(`https://ifashion.ifeng.com/new/biztjw/${id}_1/getFlagData.shtml`, {
        jsonpCallback: 'getbizNewsDatacallback',
        cache: false,
    });
    const data3 = await jsonp(`https://ifashion.ifeng.com/new/biztjw/${id}_2/getFlagData.shtml`, {
        jsonpCallback: 'getbizNewsDatacallback',
        cache: false,
    });
    let result = [];
    let result1 = [];
    let result2 = [];
    let result3 = [];

    if (data1 && data1.length !== 0) {
        result1 = data1;
    }
    if (data2 && data2.length !== 0) {
        result2 = data2;
    }
    if (data3 && data3.length !== 0) {
        result3 = data3;
    }
    result = result1.concat(result2.concat(result3));

    return result;
};

const getHomeSlideData = async url => {
    const data = await jsonp(url, {
        jsonpCallback: 'fixSlideData',
        cache: true,
    });
    let result = [];

    if (data && data.length !== 0) {
        result = data;
    }

    return result;
};

// 获取信息流首页数据
const getInfoIndexList = async () => {
    const data = await jsonp(`${apiBaseUrl}/finance/index/infoIndex/getInfoIndexList`, {
        jsonpCallback: 'getInfoIndexList',
        cache: false,
    });

    const result = data.data.map(item => ({
        thumbnails: formatImage(item.thumbnail, 144, 96),
        commentUrl: item.commentUrl,
        pcUrl: item.pcUrl,
        url: formatUrl(item.url),
        title: item.title,
        source: item.source,
        newsTime: handleNewstime(item.createdTime || item.startTime),
        skey: getSkey(item.title, item.pcUrl),
    }));

    return result;
};

// 获取信息流其他数据
const getInfoDataList = async key => {
    const data = await jsonp(`${apiBaseUrl}/finance/index/infoData/${key}/getInfoDataList`, {
        jsonpCallback: 'getInfoDataList',
        cache: false,
    });

    const result = data.data.data.map(item => ({
        thumbnails:
            item.thumbnails && item.thumbnails.image && item.thumbnails.image[0]
                ? formatImage(item.thumbnails.image[0].url, 144, 96)
                : '',
        commentUrl: item.commentUrl,
        pcUrl: item.url,
        url: formatUrl(item.url),
        title: item.title,
        source: item.source,
        newsTime: handleNewstime(item.newsTime),
        skey: item.skey,
    }));

    return result;
};

const getVideoHomeList = async pageSize => {
    const data = await jsonp(
        `http://vcis.ifeng.com/api/homePageList?channelId=20&pageSize=${pageSize}&protocol=1.1.0&from=share&isShowAd=0&positionId=0`,
        {
            jsonpCallback: 'cb',
            cache: false,
        },
    );

    return data ? data : null;
};
/* 股首 */

// 获取24小时直播数据
const getLiveData = async today => {
    return await jsonp('//api3.finance.ifeng.com/live/getday', {
        data: {
            beg: Date.parse(`${today} 00:00:00`) / 1000,
            end: Date.parse(`${today} 23:59:59`) / 1000,
            level: 1,
            dist: 1,
        },
        jsonp: 'cb',
        jsonpCallback: 'getLiveData',
        cache: false,
    });
};

// 刷新24小时直播数据
const refreshLiveData = async lastid => {
    return await jsonp('//api3.finance.ifeng.com/live/getnew', {
        data: {
            lastid,
            level: 1,
            dist: 1,
        },
        jsonp: 'cb',
        jsonpCallback: 'addNewData',
        cache: false,
    });
};

// 获取股票涨跌排行数据
const getStockRank = async type => {
    const data = await jsonp('//app.finance.ifeng.com/stockindex/getStockRank.php', {
        data: {
            type,
        },
        jsonpCallback: 'getStockRank',
        cache: false,
    });

    if (data.data) {
        data.data.forEach(item => {
            if (item.news !== '') {
                item.news = formatUrl(item.news);
            }
        });
    }

    return data;
};

// 获取资金流向排行数据
const getFundsFlowRank = async type => {
    const data = await jsonp('//app.finance.ifeng.com/stockindex/getZijinRank.php', {
        data: {
            type,
        },
        jsonpCallback: 'getFundsFlowRank',
        cache: false,
    });

    if (data.data) {
        data.data.forEach(item => {
            if (item.news !== '') {
                item.news = formatUrl(item.news);
            }
        });
    }

    return data;
};

// 获取热门股票数据
const getHotStockData = async () => {
    return await jsonp('//apiapp.finance.ifeng.com/hotstockrank', {
        data: {
            type: 'wx',
            callback: 'getHotStockData',
        },
        jsonpCallback: 'getHotStockData',
        cache: false,
    });
};

// 获取分析师数据
const getAnalyzerInfo = async (name, type) => {
    const callback = createJsonpCallbackName('updateAnalyzerInfo');

    const data = await jsonp('//app.finance.ifeng.com/gszb/user_ol.php', {
        data: {
            name,
            type,
            cb: callback,
        },
        jsonpCallback: callback,
        cache: false,
    });

    return data.map(item => ({
        url: item.url === '' ? '' : formatUrl(item.url),
        image: formatImage(item.image, 60, 60),
    }));
};

// 获取分析师答疑数据
const getQAData = async (name, type) => {
    const callback = createJsonpCallbackName('getQAData');

    return await jsonp('//app.finance.ifeng.com/gszb/a_data.php', {
        data: {
            name,
            type,
            cb: callback,
        },
        jsonpCallback: callback,
        cache: false,
    });
};

// 投资观察，获取分析师答疑，参数与股票首页不同

const getQADataTzgc = async (user, type) => {
    const callback = createJsonpCallbackName('getQAData');

    return await jsonp('//app.finance.ifeng.com/gszb/a_data.php', {
        data: {
            user,
            type,
            cb: callback,
        },
        jsonpCallback: callback,
        cache: false,
    });
};

// imarkets 直播室标题
const getZhiboTitle = async () => {
    return await jsonp('//api3.finance.ifeng.com/live/getnew', {
        data: {
            level: 1,
            dist: 1,
            cb: 'setNewCont',
        },
        cache: false,
        jsonpCallback: 'setNewCont',
    });
};

/**
 * 港股首页搜索股票推荐接口
 * @param {string} keyword
 */
const searchStockSuggest = async keyword => {
    const timestamp = Date.parse(new Date());
    const token = md5(`${timestamp}#ifeng`);

    return await jsonp(
        `http://ifeng.szfuit.com:83/hkquote/api/search.php?keyword=${keyword}&number=20&_callback=search_suggest&_timestamp=${timestamp}&_token=${token}`,
        {
            cache: false,
            jsonpCallback: 'search_suggest',
        },
    );
};

/**
 * 获取恒生指数数据
 */
const getHkStockData = async () => {
    const data = await jsonp(
        'http://api.iclient.ifeng.com/hkStockMarke?gv=6.3.9&av=0&proid=ifengnews&os=ios_12.1.4&vt=5&screen=750x1334&publishid=4002&uid=e53ec6cf349b4738b2c73f948ecbcc0f&nw=wifi&loginid=76437432&token=E2BFFC6583DC76F10D4E3147DCB1331A&st=1551752657&sn=c7107166d2de111c6f472e6f22c2900a&dlt=40.011187&dln=116.497379&dcy=%E5%8C%97%E4%BA%AC%E5%B8%82&dpr=%E5%8C%97%E4%BA%AC%E5%B8%82',
        {
            cache: false,
            jsonpCallback: 'getHkStockData',
        },
    );

    if (data.hk0000100_dly) return data.hk0000100_dly;

    return [];
};

// 获取视频播放数
const getVideoComment = async vid => {
    // const { schemaCheck } = await import('./validate');

    let apiUrl = '//survey.news.ifeng.com/api/getaccumulatorweight?';

    // 接口拼接
    if (vid.length > 0) {
        vid.forEach(item => {
            apiUrl += `key[]=${item}&`;
        });
    }

    let data = await jsonp(apiUrl, {
        data: {
            format: 'js',
            serviceid: 1,
        },
        jsonCallback: 'getVideoComment1',
        cache: false,
    });
    // const requestUrl = `//survey.news.ifeng.com/api/getaccumulatorweight?format=js&key=${vid}&callback=getaccumulator`;

    // data = schemaCheck(data.data, 'voteNumSchema', requestUrl);
    data = data.data;

    return data ? data : null;
};

// 视频首页信息流
/**
 *
 * @param {number} pageSize 数据量
 * @param {*} itemId 上一次最后一条的id，接口要的
 * @param {*} source 0 tv 1 v
 */
const getVideoHomeStream = async (pageSize, itemId, source) => {
    const data = await jsonp(
        `${apiBaseUrl}/homeStream/getHomeStream/${pageSize}/${itemId}/${source}/getVideoHomeStream1`,
        {
            jsonpCallback: 'getVideoHomeStream1',
            cache: false,
        },
    );

    return data ? data : null;
};

const getTvHomeStream = async (page, pageSize = 20) => {
    const data = await jsonp(`${apiBaseUrl}/getVideoStream/${page}/${pageSize}/${210439}/0/getTvHomeStream`, {
        jsonpCallback: 'getTvHomeStream',
        cache: false,
    });

    return data ? data : null;
};

// 获取视频播放数
const getVideoPlayNum = async vid => {
    const callback = createJsonpCallbackName('getAllComment1');
    let apiUrl = '//survey.news.ifeng.com/api/getaccumulatorweight?';

    // 接口拼接
    if (vid.length > 0) {
        vid.forEach(item => {
            apiUrl += `key[]=${item}&`;
        });
    }

    let data = await jsonp(apiUrl, {
        data: {
            format: 'js',
            serviceid: 1,
        },
        jsonpCallback: callback,
        cache: false,
    });

    data = data.data.browse;

    return data ? data : null;
};

// 获取手凤首页动态信息流数据
const getDynamicDataList = async (key, len) => {
    const length = len || 12;
    const addKey = key.replace(/-/g, '').replace(/_/g, '');
    const data = await jsonp(`${apiBaseUrl}/index/dynamicData/${key}/${length}/getDynamicDataCb${addKey}`, {
        jsonpCallback: `getDynamicDataCb${addKey}`,
        cache: false,
    });

    if (data && data.data && data.data.length > 0) {
        return data.data;
    } else {
        return null;
    }
};

// 获取手凤首页推荐位数据
const getRecommendDataList = async (key, len) => {
    const length = len || 12;
    const addKey = key;
    const data = await jsonp(`${apiBaseUrl}/index/recommendData/${key}/${length}/getRecommendDataCb${addKey}`, {
        jsonpCallback: `getRecommendDataCb${addKey}`,
        cache: false,
    });

    if (data && data.data && data.data.length > 0) {
        return data.data;
    } else {
        return null;
    }
};

// 获取手凤首页定制信息流数据
const getCustomDataList = async (key, len) => {
    const length = len || 12;
    const addKey = key.replace(/-/g, '').replace(/_/g, '');
    const data = await jsonp(`${apiBaseUrl}/index/customData/${key}/${length}/getCustomDataCb${addKey}`, {
        jsonpCallback: `getCustomDataCb${addKey}`,
        cache: false,
    });

    if (data && data.data && data.data.length > 0) {
        return data.data;
    } else {
        return null;
    }
};

// 获取手凤首页选择池信息流数据
const getSelectedPoolDataList = async (key, len) => {
    const length = len || 12;
    const addKey = key.replace(/-/g, '').replace(/_/g, '');
    const data = await jsonp(`${apiBaseUrl}/index/selectedPoolData/${key}/${length}/getSelectedPoolDataCb${addKey}`, {
        jsonpCallback: `getSelectedPoolDataCb${addKey}`,
        cache: false,
    });

    if (data && data.data && data.data.length > 0) {
        return data.data;
    } else {
        return null;
    }
};

// 获取手凤首页视频模块信息流数据
const getVideoDataList = async () => {
    const data = await jsonp('//vcis.ifeng.com/api/homePageList?channelId=20&pageSize=12&protocol=1.0.0', {
        jsonpCallback: 'getVideoCb',
        cache: false,
    });

    if (data) {
        return data;
    } else {
        return null;
    }
};
/**
 * 获取財經直播室数据
 */

// 获取财联社数据
const getClsData = async () => {
    return await jsonp('//api3.finance.ifeng.com/live/getclsnew', {
        jsonp: 'cb',
        jsonpCallback: 'getLiveData',
        cache: false,
    });
};

// 刷新财联社数据
const refreshClsData = async lastid => {
    return await jsonp('//api3.finance.ifeng.com/live/getclsnew', {
        data: {
            lastid,
        },
        jsonp: 'cb',
        jsonpCallback: 'addNewData',
        cache: false,
    });
};

// 加载更多财联社数据
const moreClsData = async lastid => {
    return await jsonp(`//api3.finance.ifeng.com/live/getclsold?lastid=${lastid}&num=10`, {
        data: {
            lastid,
        },
        jsonp: 'cb',
        jsonpCallback: 'addNewData',
        cache: false,
    });
};

/**
 * 获取视频客户端首屏推荐信息流
 */
const getVideoRecStream = async positionId => {
    const result = [];

    try {
        const firstRecVideos = await jsonp(
            `http://vcis.ifeng.com/api/homePageList?channelId=20&pageSize=10&protocol=1.1.0&from=share&isShowAd=0&positionId=${positionId}`,
            {
                jsonpCallback: 'cb',
                cache: false,
            },
        );

        if (firstRecVideos && firstRecVideos.bodyList && isArray(firstRecVideos.bodyList)) {
            firstRecVideos.bodyList.forEach(item => {
                const { title, memberItem, tag, memberType, itemId } = item;

                if (memberItem && memberItem.guid) {
                    const { guid, playTime, duration, image, ucmsId } = memberItem;

                    result.push({
                        itemId,
                        guid,
                        ucmsId,
                        tag,
                        duration: formatVideoDuration(duration),
                        playTimes: formatPlayTimes(playTime),
                        title,
                        type: memberType,
                        url: `//i.ifeng.com/c/${ucmsId ? ucmsId : guid}`,
                        thumbnail: image,
                    });
                }
            });
        }

        return result;
    } catch (e) {
        console.log(e);
    }
};

/**
 * 获取客户端精品池中财经栏目下文章
 * @param {String} selectedId 精品池id
 * @param {Number} current current
 * @param {Number} pageSize pageSize
 */
const getFinanceSelectedData = async (selectedId, current, pageSize = 20) => {
    const isVideoShare = (typeof window === 'object' && window.allData && window.allData.isVideoShare) || false;
    const proid = isVideoShare ? 'ifengvideoh5' : 'ifengnewsh5';

    return await jsonp(
        `${apiBaseUrl}/finance/studio/getSelectedPoolData/${selectedId}/${current}/${pageSize}/${proid}/getFinanceDataCb`,
        {
            jsonpCallback: 'getFinanceDataCb',
            cache: false,
        },
    );
};

/**
 * 获取新闻热点前三条数据
 */
const getHotNewsList = async () => {
    return await jsonp(`${apiBaseUrl}/index/hotNewsList/getHotNewsListCb`, {
        jsonpCallback: 'getHotNewsListCb',
        cache: false,
    });
};

export {
    getMyStockData,
    getStockData,
    getFinanceData,
    getCommentCount,
    getTopicData,
    getFundsFlowData,
    getwemediaEAccountImg,
    getInfoIndexList,
    getInfoDataList,
    getLiveData,
    refreshLiveData,
    getStockRank,
    getFundsFlowRank,
    getHotStockData,
    getAnalyzerInfo,
    getQAData,
    getQADataTzgc,
    getZhiboTitle,
    searchStockSuggest,
    getHkStockData,
    getVideoComment,
    getVideoHomeStream,
    getTvHomeStream,
    getVideoPlayNum,
    getDynamicDataList,
    getRecommendDataList,
    getCustomDataList,
    getSelectedPoolDataList,
    getClsData,
    refreshClsData,
    moreClsData,
    getVideoHomeList,
    getWeatherData,
    getKeywordData,
    getHouseData,
    getHomeLocalStationData,
    getHomeSlideData,
    getVideoDataList,
    getVideoRecStream,
    getFinanceSelectedData,
    getHotNewsList,
};
