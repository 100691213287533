import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Nav from './nav';
import Chip from 'Chip';

class PageNav extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        const { content } = this.props;
        const navData = content;

        return (
            <React.Fragment>
                {navData &&
                    navData.length > 0 && (
                        <Chip
                            id="50143"
                            type="struct"
                            title="顶部导航"
                            groupName="content"
                            content={navData}
                            translate="handleHomeNavInfo">
                            <Nav />
                        </Chip>
                    )}
            </React.Fragment>
        );
    }
}

export default errorBoundary(PageNav);
