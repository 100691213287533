import React from 'react';
import ReactDOM from 'react-dom';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';

class ChipView extends React.PureComponent {
    render() {
        const chipView = (
            <div className={styles.content_list}>
                <div className={styles.content_list_item}>
                    <Chip id="260197" type="struct" title="手凤和凤首置灰配置" groupName="手凤和凤首置灰配置">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip
                        id="75197"
                        type="recommend"
                        title="焦点图小说推荐数据"
                        groupName="小说数据维护"
                        translate="handleHomeSlideNovelInfo">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="90002" type="recommend" title="要闻区小说推荐数据" groupName="小说数据维护">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="195991" type="recommend" title="要闻区电商推荐数据" groupName="电商">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip id="285789" type="recommend" title="要闻区资讯推荐数据" groupName="资讯">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip
                        id="90003"
                        type="recommend"
                        title="资讯模块小说推荐数据"
                        translate="handleHomeNewsNovelFeedInfo"
                        groupName="小说数据维护">
                        <div className={styles.chip_item} />
                    </Chip>
                    <Chip
                        id="90004"
                        type="recommend"
                        title="娱乐模块小说推荐数据"
                        translate="handleHomeEntRecommendFeedInfo"
                        groupName="小说数据维护">
                        <div className={styles.chip_item} />
                    </Chip>

                    <div className={styles.top_40}>
                        <Chip id="285234" type="recommend" title="资讯焦点图" groupName="焦点图">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="285235" type="recommend" title="娱乐焦点图" groupName="焦点图">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="285236" type="recommend" title="体育焦点图" groupName="焦点图">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="285237" type="recommend" title="汽车焦点图" groupName="焦点图">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="285238" type="recommend" title="时尚焦点图" groupName="焦点图">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="285239" type="recommend" title="军事焦点图" groupName="焦点图">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="285240" type="recommend" title="房产焦点图" groupName="焦点图">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="285241" type="recommend" title="视频焦点图" groupName="焦点图">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195993" type="recommend" title="电商焦点图" groupName="焦点图">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="75197" type="recommend" title="小说焦点图" groupName="焦点图">
                            <div className={styles.chip_item} />
                        </Chip>
                    </div>
                    <div className={styles.top_40}>
                        <Chip id="80036" type="struct" title="地方站要闻导航数据" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195620" type="recommend" title="安徽要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255338" type="recommend" title="重庆要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195624" type="recommend" title="陕西要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195623" type="recommend" title="浙江要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225523" type="recommend" title="宁波要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225270" type="recommend" title="黑龙江要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225519" type="recommend" title="河南要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255531" type="recommend" title="江西要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225522" type="recommend" title="广东要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="287540" type="recommend" title="深圳要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255338" type="recommend" title="四川要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="255337" type="recommend" title="河北要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225268" type="recommend" title="甘肃要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195615" type="recommend" title="湖北要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225521" type="recommend" title="湖南要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225476" type="recommend" title="辽宁要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225520" type="recommend" title="天津要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="195304" type="recommend" title="山东要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225267" type="recommend" title="青岛要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="287762" type="recommend" title="吉林要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225269" type="recommend" title="长春要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="225149" type="recommend" title="青海要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="285091" type="recommend" title="江苏要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                        <Chip id="285552" type="recommend" title="福建要闻列表" groupName="地方站">
                            <div className={styles.chip_item} />
                        </Chip>
                    </div>
                    <div className={styles.top_40}>
                        <Chip id="260147" type="struct" title="冬奥会版权图片设置" groupName="冬奥会">
                            <div className={styles.chip_item} />
                        </Chip>
                    </div>
                </div>
            </div>
        );

        return ReactDOM.createPortal(chipView, document.body);
    }
}

export default errorBoundary(ChipView);
