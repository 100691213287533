import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { getDynamicDataList } from '../../../../../services/api';
import Observer from '@researchgate/react-intersection-observer';
import styles from './index.css';
import Module from '../../module';

class Wine extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        dataListArr: [],
    };

    async getDataListFn() {
        try {
            const dataListArr = await getDynamicDataList('29-');

            this.setState({
                dataListArr,
            });
        } catch (e) {
            console.error(e);
        }
    }

    handleChange = (event, unobserve) => {
        const { content } = this.props;
        const { isAsync } = content;

        if (event.isIntersecting) {
            unobserve();
            if (isAsync) {
                this.getDataListFn();
            }
        }
    };

    render() {
        const { content } = this.props;
        const { dataListArr } = this.state;
        const { wineMd, wineSlide, wineFeed, wineAd, isAsync } = content;

        const normalTabsData = wineMd;
        const newsStreamData = isAsync ? dataListArr : wineFeed;
        const mdBannerData = wineSlide;

        if (mdBannerData) {
            mdBannerData.chipConfig = {
                id: '55070',
                type: 'recommend',
                title: '酒业焦点图数据',
                groupName: 'content',
                translate: 'handleHomeWineSlideInfo',
            };
        }
        if (normalTabsData) {
            normalTabsData.chipConfig = {
                id: '50174',
                type: 'struct',
                title: '酒业子导航数据',
                groupName: 'content',
                translate: 'handleHomeWineMdInfo',
            };
        }

        const moduleData = {
            normalTabsData,
            feedAd: wineAd,
            mdBannerData,
            newsStreamData,
        };

        return (
            <Observer onChange={this.handleChange}>
                <section className={styles.wine}>
                    <Module content={moduleData} />
                </section>
            </Observer>
        );
    }
}

export default errorBoundary(Wine);
