import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { getDynamicDataList } from '../../../../../services/api';
import Observer from '@researchgate/react-intersection-observer';
import styles from './index.css';
import Module from '../../module';

class Mil extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        dataListArr: [],
    };

    async getDataListFn() {
        try {
            const dataListArr = await getDynamicDataList('75390');

            this.setState({
                dataListArr,
            });
        } catch (e) {
            console.error(e);
        }
    }

    handleChange = (event, unobserve) => {
        const { content } = this.props;
        const { isAsync } = content;

        if (event.isIntersecting) {
            unobserve();
            if (isAsync) {
                this.getDataListFn();
            }
        }
    };

    render() {
        const { content } = this.props;
        const { dataListArr } = this.state;
        const { milMd, milSlide, milFeed, milAd, isAsync } = content;

        const normalTabsData = milMd || '';
        const viewMoreLinkData = (milMd && milMd.moreTh) || '';
        const newsStreamData = isAsync ? dataListArr : milFeed;
        const mdBannerData = milSlide;

        if (normalTabsData) {
            normalTabsData.chipConfig = {
                id: '50147',
                type: 'struct',
                title: '军事子导航数据',
                groupName: 'content',
                translate: 'handleHomeMilMdInfo',
            };
        }
        if (mdBannerData) {
            mdBannerData.chipConfig = {
                id: '75202',
                type: 'recommend',
                title: '军事焦点图数据',
                groupName: 'content',
                translate: 'handleHomeMilSlideInfo',
            };
        }
        const newsStreamChipConfig = {
            id: '75198',
            type: 'recommend',
            title: '军事信息流数据',
            groupName: 'content',
            translate: 'handleHomeMinlRecommendFeedInfo',
        };
        const moduleData = {
            normalTabsData,
            feedAd: milAd,
            newsStreamChipConfig,
            mdBannerData,
            newsStreamData,
            viewMoreLinkData,
        };

        return (
            <Observer onChange={this.handleChange}>
                <section className={styles.mil}>
                    <Module content={moduleData} />
                </section>
            </Observer>
        );
    }
}

export default errorBoundary(Mil);
