import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import Module from '../../module';

class Game extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;
        const { gameMd, gameSlide, gameFeed, gameAd } = content;

        const normalTabsData = gameMd;
        const viewMoreLinkData = (gameMd && gameMd.moreTh) || '';
        const newsStreamData = gameFeed;
        const mdBannerData = gameSlide;

        if (normalTabsData) {
            normalTabsData.chipConfig = {
                id: '50160',
                type: 'struct',
                title: '媒体实验室子导航数据',
                groupName: 'content',
                translate: 'handleHomeGameMdInfo',
            };
        }
        if (mdBannerData) {
            mdBannerData.chipConfig = {
                id: '75186',
                type: 'recommend',
                title: '媒体实验室焦点图数据',
                groupName: 'content',
                translate: 'handleHomeGameSlideInfo',
            };
        }
        const newsStreamChipConfig = {
            id: '75204',
            type: 'recommend',
            title: '媒体实验室信息流数据',
            groupName: 'content',
            translate: 'handleHomeGameFeedInfo',
        };
        const moduleData = {
            normalTabsData,
            mdBannerData,
            newsStreamChipConfig,
            feedAd: gameAd,
            newsStreamData,
            viewMoreLinkData,
        };

        return (
            <section className={styles.game}>
                <Module content={moduleData} />
            </section>
        );
    }
}

export default errorBoundary(Game);
