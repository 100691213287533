import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { HomeFooter, Copyright } from '@ifeng-fe/three_terminal/es/mobile/copyright';
import Chip from 'Chip';

class Footer extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        let { content } = this.props;

        if (content && content.length > 0) {
            content = content.map((item, index) => {
                let clickConfig = undefined;

                if (item.title === '客户端') {
                    clickConfig = {
                        behavior: 'action',
                        type: 'openapp_info',
                    };
                }

                return {
                    title: item.title,
                    url: item.url,
                    clickConfig,
                };
            });
        }
        const copyrightConfig = {
            text: '京ICP证030609号',
            url: 'https://beian.miit.gov.cn/',
        };

        return (
            <React.Fragment>
                {content &&
                    content.length > 0 && (
                        <Chip
                            id="50168"
                            type="struct"
                            title="页底数据"
                            groupName="content"
                            content={content}
                            translate="handleHomeFooterInfo">
                            <HomeFooter />
                        </Chip>
                    )}
                <Copyright content={copyrightConfig} />
            </React.Fragment>
        );
    }
}

export default errorBoundary(Footer);
