import React from 'react';
import Ad from '@ifeng-fe/ui_pc_ad';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Chip from 'Chip';
import styles from './index.css';
import Header from './header';
import PageNav from './pageNav';
import Slide from './slide';
import Channel from './channel';
import AppRecommend from './appRecommend';
import LifeZone from './lifeZone';
import Footer from './footer';
import FixSlide from './fixSlide';
import GoTopBottom from './goTopBottom';
import AddDesktop from './addDesktop';
import Redbag from './redbag';
import Bookrack from './bookrack';
import BrowserGuide from './browserGuide';
import ChipView from './chipView';
import NoSSR from 'react-no-ssr';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import { hasSecretCallup } from './callupCondition';

class Layout extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        isSecretCallup: false,
    };

    UNSAFE_componentWillMount() {
        const { secretCallup } = this.props.content;

        if (typeof document === 'object' && secretCallup && hasSecretCallup()) {
            this.setState({
                isSecretCallup: true,
            });
        }
    }

    componentDidMount() {
        this.pageClickCount();
    }

    pageClickCount = () => {
        document.getElementsByTagName('body')[0].addEventListener(
            'click',
            event => {
                if (event && event.target && event.target.tagName === 'A' && navigator && navigator.sendBeacon) {
                    const data = {
                        namespace: 'shank',
                        appname: 'web_channel_spring',
                        route: '/click//mobile/i/(index)?',
                        url: event.target.getAttribute('href') || '',
                    };

                    navigator.sendBeacon('https://collect.ifengcloud.ifeng.com/v1/proxy/hb', JSON.stringify(data));
                }
            },
            false,
        );
    };

    render() {
        const { content } = this.props;
        const { isSecretCallup } = this.state;
        const {
            navigation,
            slidePic,
            footerInfo,
            appRecommend,
            dropDownAd,
            stickBannerAd,
            slideAd,
            lifeAd,
            redbag,
            headerRedTheme,
            bookrack,
            guideConfig,
            winterOlympicsCopyright,
            fullScreenAd,
        } = content;
        const slideData = {
            slidePic,
            slideAd,
        };
        const headerConfig = {
            headerRedTheme,
            winterOlympicsCopyright,
        };

        return (
            <React.Fragment>
                <NoSSR>
                    {/* 新版浮动富媒体-顶部下推广告 */}
                    <Ad content={dropDownAd} />
                    {/* 置顶banner广告位 */}
                    <Ad content={stickBannerAd} />
                </NoSSR>

                <Chip id="170058" type="struct" title="页头飘红" groupName="content" content={headerConfig} translate="handleHomeRedHeader">
                    <Header />
                </Chip>
                <PageNav content={navigation} />
                <NoSSR>
                    <Slide content={slideData} isSecretCallup={isSecretCallup} />
                </NoSSR>
                <Channel content={content} isSecretCallup={isSecretCallup} />
                <NoSSR>
                    <AppRecommend content={appRecommend} />
                </NoSSR>
                {/* <NoSSR>
                    <LifeZone ad={lifeAd} />
                </NoSSR> */}
                <Footer content={footerInfo} />
                <FixSlide />
                <GoTopBottom />
                <AddDesktop />
                <NoSSR>
                    <Redbag content={redbag} />
                </NoSSR>
                <NoSSR>
                    <Bookrack content={bookrack} />
                </NoSSR>
                <NoSSR>
                    <BrowserGuide content={guideConfig} />
                </NoSSR>
                <NoSSR>
                    <ChipView />
                </NoSSR>
                <NoSSR>
                    {/* 全屏浮层广告 */}
                    <Ad content={fullScreenAd} />
                </NoSSR>
                <ChipEdit transform={transform} />
            </React.Fragment>
        );
    }
}

export default errorBoundary(Layout);
