import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import Module from '../../module';

class Wutong extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;
        const { wutongMd, wutongSlide, wutongRecommendFeed, wutongAd } = content;

        const normalTabsData = wutongMd;
        const viewMoreLinkData = (wutongMd && wutongMd.moreTh) || '';
        const newsStreamData = wutongRecommendFeed;
        const mdBannerData = wutongSlide;

        if (normalTabsData) {
            normalTabsData.chipConfig = {
                id: '80040',
                type: 'struct',
                title: '梧桐汇子导航数据',
                groupName: 'content',
                translate: 'handleHomeWutongMdInfo',
            };
            normalTabsData.hasAdIcon = true;
        }
        if (mdBannerData) {
            mdBannerData.chipConfig = {
                id: '90007',
                type: 'recommend',
                title: '梧桐汇焦点图数据',
                groupName: 'content',
                translate: 'handleHomeWutongSlideInfo',
            };
        }
        const newsStreamChipConfig = {
            id: '135129',
            type: 'recommend',
            title: '梧桐汇信息流推荐数据',
            groupName: 'content',
            translate: 'handleHomeWutongRecommendFeedInfo',
        };

        const moduleData = {
            normalTabsData,
            newsStreamChipConfig,
            feedAd: wutongAd,
            mdBannerData,
            newsStreamData,
            viewMoreLinkData,
        };

        return (
            <section className={styles.wutong}>
                <Module content={moduleData} />
            </section>
        );
    }
}

export default errorBoundary(Wutong);
