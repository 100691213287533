import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { formatImageWebpAuto } from '@ifeng-fe/public_method';
import styles from './index.css';
import Module from '../../module';

class Travel extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;
        const { travelMd, travelSlide, travelFeed, travelAd } = content;

        const normalTabsData = travelMd;
        const viewMoreLinkData = (travelMd && travelMd.moreTh) || '';
        const newsStreamData = travelFeed;
        const indexSlidesConfig = {
            id: '75260',
            type: 'recommend',
            title: '旅游焦点图数据',
            groupName: 'content',
            translate: 'handleHomeTravelSlideInfo',
        };
        const indexSlidesData = travelSlide.map((item, index) => ({
            id: index,
            title: item.title,
            src: formatImageWebpAuto(item.image, 698, 350),
            url: item.link && item.link,
        }));

        if (normalTabsData) {
            normalTabsData.chipConfig = {
                id: '80039',
                type: 'struct',
                title: '旅游子导航数据',
                groupName: 'content',
                translate: 'handleHomeTravelMdInfo',
            };
        }
        const newsStreamChipConfig = {
            id: '75218',
            type: 'recommend',
            title: '旅游信息流数据 ',
            groupName: 'content',
            translate: 'handleHomeTravelFeedInfo',
        };
        const moduleData = {
            normalTabsData,
            indexSlidesConfig,
            indexSlidesData,
            newsStreamChipConfig,
            feedAd: travelAd,
            newsStreamData,
            viewMoreLinkData,
        };

        return (
            <section className={styles.travel}>
                <Module content={moduleData} />
            </section>
        );
    }
}

export default errorBoundary(Travel);
