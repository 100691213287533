import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Ad from '@ifeng-fe/ui_pc_ad';
import NormalTabs from '@ifeng-fe/three_terminal/es/mobile/tabs/normalTabs';
import styles from './index.css';

class LifeZone extends React.PureComponent {
    static propTypes = {
        ad: PropTypes.object,
    };

    render() {
        const { ad } = this.props;
        const NormalTabsData = {
            title: '生活专区',
            subTit: [],
        };

        return (
            <section className={styles.lifeZone}>
                <NormalTabs content={NormalTabsData} />
                {/* 生活专区广告位 */}
                <Ad content={ad} />
            </section>
        );
    }
}

export default errorBoundary(LifeZone);
