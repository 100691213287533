import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import IndexSlides from '@ifeng-fe/three_terminal/es/mobile/slides/indexSlides';
import { formatImageWebpAuto } from '@ifeng-fe/public_method';
import Chip from 'Chip';
import styles from './index.css';

class Slide extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        isSecretCallup: PropTypes.bool,
    };

    // 静默拉起方法
    addSecretAttribute = data => {
        if (!data) return data;
        if (Array.isArray(data)) {
            return data.map(item => ({
                secretCallUp: item.articleTag !== '翻阅小说',
                newstype: item.type,
                kind: 'inter_sflahuo01',
                noPrefixKind: true,
                wapactCount: {
                    value: 'direct',
                },
                ...item,
            }));
        } else {
            return {
                secretCallUp: data.articleTag !== '翻阅小说',
                newstype: data.type,
                kind: 'inter_sflahuo01',
                noPrefixKind: true,
                wapactCount: {
                    value: 'direct',
                },
                ...data,
            };
        }
    };

    render() {
        const { content, isSecretCallup } = this.props;
        let { slidePic, slideAd } = content;
        const isVisualediting = location.href.includes('visualediting');
        const emptyTag = isVisualediting ? <section className={styles.slideBox} /> : <section />;

        if (isSecretCallup) {
            slidePic = this.addSecretAttribute(slidePic);
        }
        slidePic = slidePic.map(item => ({
            ...item,
            src: formatImageWebpAuto(item.src, 698, 350),
        }));

        return (
            <section ref="slide" className={styles.slide}>
                {/* <Chip
                    id="285233"
                    type="recommend"
                    title="焦点图推荐位"
                    translate="handleHomeSlideInfo"
                    content={slidePic}> */}
                {slidePic && slidePic.length > 0 ? <IndexSlides content={slidePic} ad={slideAd} /> : emptyTag}
                {/* </Chip> */}
            </section>
        );
    }
}

export default errorBoundary(Slide);
