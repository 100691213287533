import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { getDynamicDataList } from '../../../../../services/api';
import Observer from '@researchgate/react-intersection-observer';
import styles from './index.css';
import Module from '../../module';

class Society extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        dataListArr: [],
    };

    async getDataListFn() {
        try {
            const dataListArr = await getDynamicDataList('3-35201-');

            this.setState({
                dataListArr,
            });
        } catch (e) {
            console.error(e);
        }
    }

    handleChange = (event, unobserve) => {
        const { content } = this.props;
        const { isAsync } = content;

        if (event.isIntersecting) {
            unobserve();
            if (isAsync) {
                this.getDataListFn();
            }
        }
    };

    render() {
        const { content } = this.props;
        const { dataListArr } = this.state;
        const { societyMd, societySlide, societyFeed, societyAd, isAsync } = content;

        const normalTabsData = societyMd;
        const viewMoreLinkData = (societyMd && societyMd.moreTh) || '';
        const newsStreamData = isAsync ? dataListArr : societyFeed;
        const mdBannerData = societySlide;

        if (normalTabsData) {
            normalTabsData.chipConfig = {
                id: '50155',
                type: 'struct',
                title: '社会子导航数据',
                groupName: 'content',
                translate: 'handleHomeSocietyMdInfo',
            };
        }
        const moduleData = {
            normalTabsData,
            feedAd: societyAd,
            mdBannerData,
            newsStreamData,
            viewMoreLinkData,
        };

        return (
            <Observer onChange={this.handleChange}>
                <section className={styles.society}>
                    <Module content={moduleData} />
                </section>
            </Observer>
        );
    }
}

export default errorBoundary(Society);
