import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { getDynamicDataList } from '../../../../../services/api';
import Observer from '@researchgate/react-intersection-observer';
import styles from './index.css';
import Module from '../../module';

class Picture extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        dataListArr: [],
    };

    async getDataListFn() {
        try {
            let dataListArr = await getDynamicDataList('3-35202-');

            dataListArr = dataListArr.map((item, index) => ({
                id: item.id || index,
                type: 'slide',
                newStyle: 'singletitle',
                title: item.title || '',
                url: item.url || '',
                commentUrl: item.commentUrl || '',
                thumbnails: item.thumbnails,
                source: item.source || '',
                base62Id:
                    (item.base62Id && item.base62Id.replace('ucms_', '')) ||
                    (item.commentUrl && item.commentUrl.replace('ucms_', '')) ||
                    undefined,
            }));

            this.setState({
                dataListArr,
            });
        } catch (e) {
            console.error(e);
        }
    }

    handleChange = (event, unobserve) => {
        const { content } = this.props;
        const { isAsync } = content;

        if (event.isIntersecting) {
            unobserve();
            if (isAsync) {
                this.getDataListFn();
            }
        }
    };

    render() {
        const { content } = this.props;
        const { dataListArr } = this.state;
        const { pictureMd, pictureSlide, pictureFeed, pictureAd, isAsync } = content;

        const normalTabsData = pictureMd;
        const viewMoreLinkData = (pictureMd && pictureMd.moreTh) || '';
        const newsStreamData = isAsync ? dataListArr : pictureFeed;
        const mdBannerData = pictureSlide;

        if (normalTabsData) {
            normalTabsData.chipConfig = {
                id: '50158',
                type: 'struct',
                title: '图片子导航数据',
                groupName: 'content',
                translate: 'handleHomePictureMdInfo',
            };
        }
        const moduleData = {
            normalTabsData,
            feedAd: pictureAd,
            mdBannerData,
            newsStreamData,
            viewMoreLinkData,
        };

        return (
            <Observer onChange={this.handleChange}>
                <section className={styles.picture}>
                    <Module content={moduleData} />
                </section>
            </Observer>
        );
    }
}

export default errorBoundary(Picture);
