import React from 'react';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import PropTypes from 'prop-types';
import ifWeather from './weatherData';
import { getWeatherData, getKeywordData } from '../../../../services/api';
import { cookie } from '@ifeng-fe/ui_base';
import Chip from 'Chip';

class Header extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };
    state = {
        smKeyword: '',
        inputVal: '',
        weather: [],
    };

    /**
     * 渲染网页布局
     */
    componentDidMount() {
        // this.inputKeyword();
        this.weatherInfo();
    }

    async weatherInfo() {
        const city = this.getCity();

        try {
            const dataCb = await getWeatherData(city);

            if (dataCb.areaCode === undefined || dataCb.observe === undefined) {
                this.setState({
                    weather: [],
                });
            } else {
                const areaid = dataCb.areaCode;
                const w_observe = dataCb.observe[areaid]['1001002'];
                const w_nav = [
                    city,
                    w_observe['002'] ? `${w_observe['002']}℃` : '-',
                    ifWeather.phenomena.data[w_observe['001']].ico,
                    ifWeather.phenomena.data[w_observe['001']].gold_ico,
                ];

                this.setState({
                    weather: w_nav,
                });
            }
        } catch (e) {
            console.error(e);
        }
    }
    getCity() {
        const w_city_cn = cookie.get('weather_city');
        let w_city = '北京';

        if (w_city_cn) {
            w_city = ifWeather.getcity[w_city_cn] || ifWeather.getcity[w_city_cn.split('_')[0]] || w_city;
        }

        return w_city;
    }

    // async inputKeyword() {
    //     try {
    //         const data = await getKeywordData();
    //         let hot = [];

    //         if (!data) return '';
    //         for (let i = 0; i < data.length; i++) {
    //             hot.push(data[i].title);
    //         }
    //         const index = Math.floor(Math.random() * hot.length);

    //         this.setState({
    //             smKeyword: hot[index],
    //         });
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }
    changeValFn = event => {
        let inputVal = event.currentTarget.value;

        if (inputVal === '') {
            inputVal = '';
        }
        this.setState({
            inputVal,
        });
    };

    hasRedHeader = () => {
        const { content } = this.props;
        const headerRedTheme = content.headerRedTheme;
        const timeStampdataNow = new Date().getTime();
        let timeDiff = false;

        if (headerRedTheme && headerRedTheme.startTime && headerRedTheme.endTime) {
            const startTimeStampdata =
                new Date(headerRedTheme.startTime).getTime() ||
                new Date(headerRedTheme.startTime.replace(/-/g, '/')).getTime();
            const endTimeStampdata =
                new Date(headerRedTheme.endTime).getTime() ||
                new Date(headerRedTheme.endTime.replace(/-/g, '/')).getTime();

            if (startTimeStampdata < timeStampdataNow && endTimeStampdata > timeStampdataNow) {
                timeDiff = true;
            }
        }

        if (
            headerRedTheme &&
            headerRedTheme.isBegin &&
            headerRedTheme.isBegin === true &&
            timeDiff &&
            headerRedTheme.image
        ) {
            return true;
        }

        return false;
    };
    submitFn = () => {
        const { inputVal, smKeyword } = this.state;
        let searchWork = inputVal;

        if (inputVal === '') {
            searchWork = smKeyword;
        }

        location.href = `https://yz.m.sm.cn/s?from=wm818445&q=${searchWork}`;
    };

    render() {
        const { content } = this.props;
        const { inputVal, weather, smKeyword } = this.state;
        const headerRedTheme = content.headerRedTheme;
        const winterOlympicsCopyright = content.winterOlympicsCopyright;

        return (
            <React.Fragment>
                {this.hasRedHeader() ? (
                    <header className={styles.redHeader}>
                        <img className={styles.redHeaderImg} src={headerRedTheme.image} />
                        {/* {weather.length > 0 && (
                            <a href="http://inews.ifeng.com/weather.shtml#/home" className={styles.weather}>
                                <img className={styles.weatherPic} src={weather[3]} />
                                <div className={styles.weatherInfo}>
                                    <p className={styles.temperature}>{weather[1]}</p>
                                    <h2 className={styles.city}>{weather[0]}</h2>
                                </div>
                            </a>
                        )} */}
                        {winterOlympicsCopyright.winterOlympicsSwitch === '1' && (
                            <a
                                className={styles.winterOlympicsCopyright}
                                href={winterOlympicsCopyright.winterOlympicsUrl}>
                                <img src={headerRedTheme.copyrightImage} />
                            </a>
                        )}
                    </header>
                ) : (
                    <header className={styles.header}>
                        <span className={styles.logo} />
                        <section className={styles.searchBox}>
                            {/* <input
                            placeholder={smKeyword}
                            className={styles.searchVal}
                            onChange={this.changeValFn}
                            value={inputVal}
                            type="text"
                        />
                        <a href="javascript:;" onClick={this.submitFn} className={styles.button}>
                            搜索
                        </a> */}
                        </section>
                        {weather.length > 0 && (
                            <a href="http://inews.ifeng.com/weather.shtml#/home" className={styles.weather}>
                                <img className={styles.weatherPic} src={weather[2]} />
                                <div className={styles.weatherInfo}>
                                    <p className={styles.temperature}>{weather[1]}</p>
                                    <h2 className={styles.city}>{weather[0]}</h2>
                                </div>
                            </a>
                        )}
                        {winterOlympicsCopyright.winterOlympicsSwitch === '1' && (
                            <a
                                className={styles.winterOlympicsCopyright}
                                href={winterOlympicsCopyright.winterOlympicsUrl}>
                                <img src={winterOlympicsCopyright.winterOlympicsPic} />
                            </a>
                        )}
                    </header>
                )}
            </React.Fragment>
        );
    }
}

export default errorBoundary(Header);
