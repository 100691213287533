import React from 'react';
import errorBoundary from '@ifeng-fe/errorBoundary';
import BackTopBottom from '@ifeng-fe/three_terminal/es/mobile/backTopBottom/backTopBottom';

class GoTopBottom extends React.PureComponent {
    render() {
        return <BackTopBottom />;
    }
}

export default errorBoundary(GoTopBottom);
