import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import IndexSlides from '@ifeng-fe/three_terminal/es/mobile/slides/indexSlides';
import NormalTabs from '@ifeng-fe/three_terminal/es/mobile/tabs/normalTabs';
import MdBanner from '@ifeng-fe/three_terminal/es/mobile/mdBanner/mdBanner';
import InformationFlow from '@ifeng-fe/three_terminal/es/mobile/informationFlow/informationFlow';
import ViewMoreLink from '@ifeng-fe/three_terminal/es/mobile/tabs/viewMoreLink';
import DataBox from '@ifeng-fe/three_terminal/es/mobile/dataBox/dataBox';
import FourNavPic from '@ifeng-fe/three_terminal/es/mobile/tilePic/fourNavPic';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NoSSR from 'react-no-ssr';
import styles from './index.css';

class Module extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };
    state = {
        isMore: true,
        isLoadFeed: true,
        isLoadLocalFeed: true,
        selfNum: 0,
        isLocalMore: true,
        isLocalLoadFeed: true,
        selfLocalNum: 0,
        newsStreamData: this.props.content.newsStreamData,
        localStationData: this.props.content.localStationData,
        firstData: [],
        loadData: [],
        firstLocalData: [],
        loadLocalData: [],
        switchTabStatus: true,
    };

    componentDidMount() {
        this.loadFeedData();
    }

    componentDidUpdate() {
        const { newsStreamData, localStationData, isLoadFeed, isLoadLocalFeed } = this.state;

        if (isLoadFeed && newsStreamData && newsStreamData.length > 0) {
            this.loadFeedData();
            this.setState({
                isLoadFeed: false,
            });
        }
        if (isLoadLocalFeed && localStationData && localStationData.length > 0) {
            this.loadLocalStationData();
            this.setState({
                isLoadLocalFeed: false,
            });
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.content.newsStreamData !== state.newsStreamData) {
            return {
                newsStreamData: props.content.newsStreamData,
            };
        }
        if (props.content.localStationData !== state.localStationData) {
            return {
                localStationData: props.content.localStationData,
            };
        }

        return null;
    }

    loadFeedData() {
        const { content } = this.props;
        const { showNum = 4, stepNum = 4, countNum = 12 } = content;
        const { firstData, newsStreamData, selfNum } = this.state;

        if (!newsStreamData || newsStreamData.length === 0) return '';

        if (firstData.length === 0) {
            this.setState({
                firstData: [...this.state.firstData, ...newsStreamData.slice(0, showNum)],
                selfNum: showNum,
                isLoadFeed: false,
            });
        } else if (selfNum < countNum && selfNum < newsStreamData.length) {
            const addNum = selfNum + stepNum;
            const endNum = addNum > countNum ? countNum : addNum;

            this.setState({
                loadData: content.newsStreamData.slice(selfNum, endNum),
                selfNum: endNum,
            });
            if (endNum >= countNum || endNum >= newsStreamData.length) {
                this.setState({
                    isMore: false,
                });
            }
        } else {
            this.setState({
                isMore: false,
                isLoadFeed: false,
            });
        }
    }
    loadLocalStationData() {
        const { content } = this.props;
        const { showLocalNum = 11, stepLocalNum = 11, countLocalNum = 33 } = content;
        const { firstLocalData, selfLocalNum, localStationData } = this.state;

        if (!localStationData || localStationData.length === 0) return '';

        if (firstLocalData.length === 0) {
            this.setState({
                firstLocalData: [...this.state.firstLocalData, ...localStationData.slice(0, showLocalNum)],
                selfLocalNum: showLocalNum,
                isLoadFeed: false,
            });
        } else if (selfLocalNum < countLocalNum && selfLocalNum < localStationData.length) {
            const addNum = selfLocalNum + stepLocalNum;
            const endNum = addNum > countLocalNum ? countLocalNum : addNum;

            this.setState({
                loadLocalData: content.localStationData.slice(selfLocalNum, endNum),
                selfLocalNum: endNum,
            });
            if (endNum >= countLocalNum || endNum >= localStationData.length) {
                this.setState({
                    isLocalMore: false,
                });
            }
        } else {
            this.setState({
                isLocalMore: false,
                isLocalLoadFeed: false,
            });
        }
    }

    handleMoreData = async _this => {
        try {
            await this.loadFeedData();
            const { loadData, isMore } = this.state;

            return {
                content: loadData,
                isEnd: !isMore,
                callback: _this.handleLazyImageScroll,
            };
        } catch (e) {
            console.error(e);
        }
    };

    handleLocalMoreData = async _this => {
        try {
            await this.loadLocalStationData();
            const { loadLocalData, isLocalMore } = this.state;

            return {
                content: loadLocalData,
                isEnd: !isLocalMore,
                callback: _this.handleLazyImageScroll,
            };
        } catch (e) {
            console.error(e);
        }
    };
    switchSelectTabFn = statue => {
        this.setState({
            switchTabStatus: statue,
        });
    };

    render() {
        const { content } = this.props;
        const { isMore, firstData, isLocalMore, firstLocalData, switchTabStatus } = this.state;
        const {
            normalTabsData,
            stickFeedChipConfig,
            stickFeed,
            mdBannerData,
            feedAd,
            newsStreamChipConfig,
            LocalStationChipConfig,
            viewMoreLinkData,
            stockInfoData,
            twoPicData,
            twoPicChipConfig,
            fourPicData,
            fourPicChipConfig,
            indexSlidesData,
            indexSlidesConfig,
            isStickBottom,
        } = content;
        const feedAdVal = feedAd ? [feedAd] : null;
        const tabChip = (normalTabsData && normalTabsData.chipConfig) || '';
        const mdBannerChip = (mdBannerData && mdBannerData.chipConfig) || '';
        const hasAdIcon = normalTabsData?.hasAdIcon || false;
        const normalTabsDom = tabChip
            ? normalTabsData && (
                  <Chip {...tabChip} content={normalTabsData}>
                      <NormalTabs hasSticky={true} hasAdIcon={hasAdIcon} switchSelectTab={this.switchSelectTabFn} />
                  </Chip>
              )
            : normalTabsData && <NormalTabs hasSticky={true} hasAdIcon={hasAdIcon} content={normalTabsData} />;
        const mdBannerDom = mdBannerChip
            ? mdBannerData && (
                  <Chip {...mdBannerChip} content={mdBannerData}>
                      <MdBanner />
                  </Chip>
              )
            : mdBannerData && <MdBanner content={mdBannerData} />;
        const stickFeedDom = stickFeedChipConfig
            ? stickFeed &&
              stickFeed.length > 0 && (
                  <NoSSR>
                      <section className={isStickBottom ? styles.stickBorder : ''}>
                          <Chip {...stickFeedChipConfig} content={stickFeed}>
                              <InformationFlow isLazyImage={true} isDisplayLoadingMore={false} hasProtocol={true} />
                          </Chip>
                      </section>
                  </NoSSR>
              )
            : stickFeed &&
              stickFeed.length > 0 && (
                  <NoSSR>
                      <section className={isStickBottom ? styles.stickBorder : ''}>
                          <InformationFlow content={stickFeed} hasProtocol={true} isLazyImage={true} isDisplayLoadingMore={false} />
                      </section>
                  </NoSSR>
              );
        const newsStreamDom = newsStreamChipConfig
            ? firstData.length > 0 && (
                  <Chip {...newsStreamChipConfig} content={firstData}>
                      <InformationFlow isLazyImage={true} isSpreadMoreIcon={true} handleLoadingDataFunc={this.handleMoreData} hasProtocol={true} isDisplayLoadingMore={isMore} ad={feedAdVal} />
                  </Chip>
              )
            : firstData.length > 0 && (
                  <InformationFlow
                      content={firstData}
                      isLazyImage={true}
                      isSpreadMoreIcon={true}
                      handleLoadingDataFunc={this.handleMoreData}
                      isDisplayLoadingMore={isMore}
                      hasProtocol={true}
                      ad={feedAdVal}
                  />
              );
        const localStationDom = LocalStationChipConfig
            ? firstLocalData.length > 0 && (
                  <Chip {...newsStreamChipConfig} content={firstLocalData}>
                      <InformationFlow isLazyImage={true} isSpreadMoreIcon={true} hasProtocol={true} handleLoadingDataFunc={this.handleLocalMoreData} isDisplayLoadingMore={isLocalMore} />
                  </Chip>
              )
            : firstLocalData.length > 0 && (
                  <InformationFlow
                      content={firstLocalData}
                      isLazyImage={true}
                      isSpreadMoreIcon={true}
                      hasProtocol={true}
                      handleLoadingDataFunc={this.handleLocalMoreData}
                      isDisplayLoadingMore={isLocalMore}
                  />
              );
        const fourPicDom = fourPicChipConfig
            ? fourPicData &&
              fourPicData.length > 0 && (
                  <Chip {...fourPicChipConfig} content={fourPicData}>
                      <FourNavPic />
                  </Chip>
              )
            : fourPicData && fourPicData.length > 0 && <FourNavPic content={fourPicData} />;
        const twoPicDom = twoPicChipConfig
            ? twoPicData &&
              twoPicData.length > 0 && (
                  <Chip {...twoPicChipConfig} content={twoPicData}>
                      <InformationFlow isLazyImage={true} isDisplayLoadingMore={false} hasProtocol={true} />
                  </Chip>
              )
            : twoPicData && twoPicData.length > 0 && <InformationFlow content={twoPicData} isLazyImage={true} isDisplayLoadingMore={false} hasProtocol={true} />;
        const indexSlidesDom = indexSlidesConfig
            ? indexSlidesData &&
              indexSlidesData.length > 0 && (
                  <NoSSR>
                      <Chip {...indexSlidesConfig} content={indexSlidesData}>
                          <IndexSlides content={indexSlidesData} />
                      </Chip>
                  </NoSSR>
              )
            : indexSlidesData &&
              indexSlidesData.length > 0 && (
                  <NoSSR>
                      <IndexSlides content={indexSlidesData} />
                  </NoSSR>
              );

        const stockDom = stockInfoData &&
            stockInfoData.length > 0 && (
                // <a href="//ifinance.ifeng.com/details.shtml#/">
                //     <DataBox content={stockInfoData} />
                // </a>
                <a href="https://finance.ifeng.com/marketCenter">
                    <DataBox content={stockInfoData} />
                </a>
            );
        let feedStyle = {};
        let feedTabStyle = {};

        if (firstLocalData.length > 0) {
            if (switchTabStatus) {
                feedStyle = { display: 'block' };
                feedTabStyle = { display: 'none' };
            } else {
                feedStyle = { display: 'none' };
                feedTabStyle = { display: 'block' };
            }
        }
        const feedListDom = (
            <React.Fragment>
                <section style={feedStyle}>{newsStreamDom}</section>
                {firstLocalData.length > 0 && <section style={feedTabStyle}>{localStationDom}</section>}
            </React.Fragment>
        );

        return (
            <React.Fragment>
                {/* 模块头 */}
                {normalTabsDom}
                {/* 模板banner图 */}
                {mdBannerDom}
                {/* 置顶新闻 */}
                {stickFeedDom}
                {/* 焦点图 */}
                {indexSlidesDom}
                {/* 四小图模块 */}
                {fourPicDom}
                {/* 两大图 */}
                {twoPicDom}
                {/* 股票趋势 */}
                {stockDom}
                {/* 信息流和地方站信息 */}
                {feedListDom}
                {/* 进入频道页链接 */}
                {viewMoreLinkData && !isMore && <ViewMoreLink content={viewMoreLinkData} />}
            </React.Fragment>
        );
    }
}

export default errorBoundary(Module);
