import React from 'react';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import PageBottomBrand from '@ifeng-fe/three_terminal/es/mobile/pageBottomBrand/pageBottomBrand';
import { h5PageCount } from '@ifeng-fe/three_terminal/es/utils';

class FixSlide extends React.PureComponent {
    render() {
        const callAppConfig = {
            docType: 'awake',
            kind: 'df_sf01',
            download: 'https://a.app.qq.com/o/simple.jsp?pkgname=com.ifeng.news2&ckey=CK1429035767411',
            downloadAd: 'https://statistics.appstore.ifeng.com/index.php/api/godownload?app_id=4&d_ch=7675',
        };
        const actionConfig = [
            {
                behavior: 'action',
                type: 'openapp_pop',
            },
        ];

        const openShare = () => {
            h5PageCount(actionConfig);
        };

        return (
            <section className={styles.fixSlide}>
                <div className={styles.fixSlideBox}>
                    <a href="https://ishare.ifeng.com/loc/webdaoliu/index" target="_blank" className={styles.qr} onClick={openShare}>
                        <img className={styles.fixSlideImg} src="https://x0.ifengimg.com/fe/custom/2023_11_10/h5banner.8fea0ac35645.png" />
                    </a>

                    {/* <PageBottomBrand callAppConfig={callAppConfig} actionConfig={actionConfig} /> */}
                </div>
            </section>
        );
    }
}

export default errorBoundary(FixSlide);
