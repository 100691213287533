import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import Module from '../../module';

class Novel extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;
        const { novelMd, novelSlide, novelFeed, novelAd } = content;

        const normalTabsData = novelMd;
        const viewMoreLinkData = (novelMd && novelMd.moreTh) || '';
        const newsStreamData = novelFeed;
        const mdBannerData = novelSlide;

        if (normalTabsData) {
            normalTabsData.chipConfig = {
                id: '50154',
                type: 'struct',
                title: '小说子导航数据',
                groupName: 'content',
                translate: 'handleHomeNovelMdInfo',
            };
        }
        if (mdBannerData) {
            mdBannerData.chipConfig = {
                id: '75184',
                type: 'recommend',
                title: '小说焦点图数据',
                groupName: 'content',
                translate: 'handleHomeNovelSlideInfo',
            };
        }
        const newsStreamChipConfig = {
            id: '75205',
            type: 'recommend',
            title: '小说信息流数据',
            groupName: 'content',
            translate: 'handleHomeNovelFeedInfo',
        };
        const moduleData = {
            normalTabsData,
            feedAd: novelAd,
            newsStreamChipConfig,
            mdBannerData,
            newsStreamData,
            viewMoreLinkData,
        };

        return (
            <section className={styles.novel}>
                <Module content={moduleData} />
            </section>
        );
    }
}

export default errorBoundary(Novel);
