import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { getDynamicDataList } from '../../../../../services/api';
import { formatImage } from '@ifeng-fe/public_method';
import Observer from '@researchgate/react-intersection-observer';
import styles from './index.css';
import Module from '../../module';

class Vip extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        dataListArr: [],
    };

    async getDataListFn() {
        try {
            const dataListArr = await getDynamicDataList('90003');

            this.setState({
                dataListArr,
            });
        } catch (e) {
            console.error(e);
        }
    }

    handleChange = (event, unobserve) => {
        const { content } = this.props;
        const { isAsync } = content;

        if (event.isIntersecting) {
            unobserve();
            if (isAsync) {
                this.getDataListFn();
            }
        }
    };

    render() {
        const { content } = this.props;
        const { dataListArr } = this.state;
        const { vipMd, vipSlide, vipFeed, vipAd, isAsync } = content;

        const normalTabsData = vipMd;
        const viewMoreLinkData = (vipMd && vipMd.moreTh) || '';
        const newsStreamData = isAsync ? dataListArr : vipFeed;
        let indexSlidesData = '';
        let mdBannerData = {};
        let indexSlidesConfig = '';

        if (vipSlide.length > 1) {
            indexSlidesConfig = {
                id: '90006',
                type: 'recommend',
                title: 'vip焦点图数据',
                groupName: 'content',
                translate: 'handleHomeVipSlideInfo',
            };
            const vipSlideData = vipSlide.map((item, index) => ({
                id: index,
                title: item.title,
                src: formatImage(item.image, 698, 350),
                url: item.link && item.link,
            }));

            indexSlidesData = vipSlideData;
            mdBannerData = '';
        } else {
            mdBannerData.chipConfig = {
                id: '90006',
                type: 'recommend',
                title: 'vip焦点图数据',
                groupName: 'content',
                translate: 'handleHomeVipSlideInfo',
            };
            mdBannerData = vipSlide[0];
        }

        if (normalTabsData) {
            normalTabsData.chipConfig = {
                id: '140035',
                type: 'struct',
                title: 'vip子导航数据',
                groupName: 'content',
                translate: 'handleHomeVipMdInfo',
            };
        }

        const moduleData = {
            normalTabsData,
            feedAd: vipAd,
            mdBannerData,
            indexSlidesData,
            indexSlidesConfig,
            newsStreamData,
            viewMoreLinkData,
        };

        return (
            <Observer onChange={this.handleChange}>
                <section className={styles.vip}>
                    <Module content={moduleData} />
                </section>
            </Observer>
        );
    }
}

export default errorBoundary(Vip);
