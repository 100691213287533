import { loadScript, jsonp } from '@ifeng-fe/ui_base';
import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Observer from '@researchgate/react-intersection-observer';
import { getCustomDataList } from '../../../../../services/api';
import styles from './index.css';
import Module from '../../module';

class Finance extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };
    state = {
        stockInfoData: [],
        dataListArr: [],
    };

    componentDidMount() {
        this.getStockTrend();
    }

    async getStockTrend() {
        try {
            const res = await jsonp('https://shankapi.ifeng.com/finance/page/newest/h5/SH0001,SZ9001,HSI/getData1', {
                jsonpCallback: 'getData1',
                cache: false,
            });
  
            const stockInfoData = [
                {
                    title: '上证指数',
                    value: res?.data?.SH0001?.tradePrice ?? '--',
                    grow: res?.data?.SH0001?.range ?? '--',
                    gain: res?.data?.SH0001?.rangePercent ?? '--',
                },
                {
                    title: '深证指数',
                    value: res?.data?.SZ9001?.tradePrice ?? '--',
                    grow: res?.data?.SZ9001?.range ?? '--',
                    gain: res?.data?.SZ9001?.rangePercent ?? '--',
                },
                {
                    title: '香港恒生指数',
                    value: res?.data?.HSI?.tradePrice ?? '--',
                    grow: res?.data?.HSI?.range ?? '--',
                    gain: res?.data?.HSI?.rangePercent ?? '--',
                },
            ];

            this.setState({
                stockInfoData,
            });
        } catch (e) {
            console.error(e);
        }
    }

    // async getStockTrend() {
    //     try {
    //         await loadScript('//hq.finance.ifeng.com/q.php?l=s_sh000001,s_sz399001,s_sz399006&f=json&from=index');
    //         const stockInfoData = [
    //             {
    //                 title: '上证指数',
    //                 value: (window.json_q && window.json_q.s_sh000001 && window.json_q.s_sh000001[0]) || '',
    //                 grow: (window.json_q && window.json_q.s_sh000001 && window.json_q.s_sh000001[2]) || '',
    //                 gain: (window.json_q && window.json_q.s_sh000001 && window.json_q.s_sh000001[3]) || '',
    //             },
    //             {
    //                 title: '深证指数',
    //                 value: (window.json_q && window.json_q.s_sz399001 && window.json_q.s_sz399001[0]) || '',
    //                 grow: (window.json_q && window.json_q.s_sz399001 && window.json_q.s_sz399001[2]) || '',
    //                 gain: (window.json_q && window.json_q.s_sz399001 && window.json_q.s_sz399001[3]) || '',
    //             },
    //             {
    //                 title: '创业板指',
    //                 value: (window.json_q && window.json_q.s_sz399006 && window.json_q.s_sz399006[0]) || '',
    //                 grow: (window.json_q && window.json_q.s_sz399006 && window.json_q.s_sz399006[2]) || '',
    //                 gain: (window.json_q && window.json_q.s_sz399006 && window.json_q.s_sz399006[3]) || '',
    //             },
    //         ];

    //         this.setState({
    //             stockInfoData,
    //         });
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }

    async getDataListFn() {
        try {
            const dataListArr = await getCustomDataList('17007_719_68');

            this.setState({
                dataListArr,
            });
        } catch (e) {
            console.error(e);
        }
    }

    handleChange = (event, unobserve) => {
        const { content } = this.props;
        const { isAsync } = content;

        if (event.isIntersecting) {
            unobserve();
            if (isAsync) {
                this.getDataListFn();
            }
        }
    };

    render() {
        const { content } = this.props;
        const { stockInfoData, dataListArr } = this.state;
        const { financeMd, financeSlide, financeRecommendFeed, financeAd, isAsync } = content;

        const normalTabsData = financeMd;
        const viewMoreLinkData = (financeMd && financeMd.moreTh) || '';
        const newsStreamData = isAsync ? dataListArr : financeRecommendFeed;
        const mdBannerData = financeSlide;

        if (normalTabsData) {
            normalTabsData.chipConfig = {
                id: '50145',
                type: 'struct',
                title: '财经子导航数据',
                groupName: 'content',
                translate: 'handleHomeFinanceMdInfo',
            };
        }
        if (mdBannerData) {
            mdBannerData.chipConfig = {
                id: '75181',
                type: 'recommend',
                title: '财经焦点图数据',
                groupName: 'content',
                translate: 'handleHomeFinanceSlideInfo',
            };
        }
        const newsStreamChipConfig = {
            id: '90005',
            type: 'recommend',
            title: '财经信息流推荐数据 ',
            groupName: 'content',
            translate: 'handleHomeEntRecommendFeedInfo',
        };
        const moduleData = {
            normalTabsData,
            feedAd: financeAd,
            newsStreamChipConfig,
            mdBannerData,
            newsStreamData,
            viewMoreLinkData,
            stockInfoData,
        };

        return (
            <Observer onChange={this.handleChange}>
                <section className={styles.finance}>
                    <Module content={moduleData} />
                </section>
            </Observer>
        );
    }
}

export default errorBoundary(Finance);
