import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { getDynamicDataList } from '../../../../../services/api';
import Observer from '@researchgate/react-intersection-observer';
import styles from './index.css';
import Module from '../../module';

class Ent extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        dataListArr: [],
    };

    async getDataListFn() {
        try {
            const dataListArr = await getDynamicDataList('3-35191-', 19);

            this.setState({
                dataListArr,
            });
        } catch (e) {
            console.error(e);
        }
    }

    handleChange = (event, unobserve) => {
        const { content } = this.props;
        const { isAsync } = content;

        if (event.isIntersecting) {
            unobserve();
            if (isAsync) {
                this.getDataListFn();
            }
        }
    };

    render() {
        const { content } = this.props;
        const { entMd, entSlide, entAllFeedData, entAd, isAsync } = content;
        const { dataListArr } = this.state;
        const normalTabsData = entMd;
        const viewMoreLinkData = (entMd && entMd.moreTh) || '';
        const newsStreamData = isAsync ? dataListArr : entAllFeedData;
        const mdBannerData = entSlide;

        if (normalTabsData) {
            normalTabsData.chipConfig = {
                id: '50146',
                type: 'struct',
                title: '娱乐子导航数据',
                groupName: 'content',
                translate: 'handleHomeEntMdInfo',
            };
        }
        if (mdBannerData) {
            mdBannerData.chipConfig = {
                id: '75195',
                type: 'recommend',
                title: '娱乐焦点图数据',
                groupName: 'content',
                translate: 'handleHomeEntSlideInfo',
            };
        }
        const newsStreamChipConfig = {
            id: '75304',
            type: 'recommend',
            title: '娱乐信息流推荐数据 ',
            groupName: 'content',
            translate: 'handleHomeEntRecommendFeedInfo',
        };
        const moduleData = {
            normalTabsData,
            feedAd: entAd,
            newsStreamChipConfig,
            mdBannerData,
            newsStreamData,
            viewMoreLinkData,
        };

        return (
            <Observer onChange={this.handleChange}>
                <section className={styles.ent}>
                    <Module content={moduleData} />
                </section>
            </Observer>
        );
    }
}

export default errorBoundary(Ent);
