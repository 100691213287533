import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NormalTabs from '@ifeng-fe/three_terminal/es/mobile/tabs/normalTabs';
import ThreeNavPic from '@ifeng-fe/three_terminal/es/mobile/tilePic/threeNavPic';
import styles from './index.css';
import Chip from 'Chip';

class AppRecommend extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        const { content } = this.props;
        const NormalTabsData = {
            title: '应用推荐',
            subTit: [],
        };

        const isAndroid = typeof navigator === 'object' ? navigator.userAgent.match(/android/gi) : false;

        const appRecomData =
            content &&
            content.map(item => {
                const url = isAndroid ? item.androidurl : item.iosurl;
                let clickConfig = undefined;

                if (item.title === '凤凰新闻') {
                    clickConfig = {
                        behavior: 'action',
                        type: 'openapp_info',
                    };
                }

                return {
                    title: item.title || '',
                    image: item.image || '',
                    url,
                    clickConfig,
                };
            });

        return (
            <section className={styles.appRecommend}>
                <NormalTabs content={NormalTabsData} />
                {content &&
                    content.length > 0 && (
                        <Chip id="50169" type="struct" title="应用推荐" groupName="content" content={appRecomData} translate="handleHomeAppRecommendInfo">
                            <ThreeNavPic />
                        </Chip>
                    )}
            </section>
        );
    }
}

export default errorBoundary(AppRecommend);
