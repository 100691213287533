// import storage from '../base/base/storage';
/**
 * 置顶数据处理
 */

const stickFeedDataFormatHandle = stickFeed => {
    const stickFeedAllArr = [];

    stickFeed.forEach(item => {
        let stickFeedItemArr = [];

        stickFeedItemArr.push(item);
        if (item.children) {
            item.children.forEach(childItem => {
                stickFeedItemArr.push(childItem);
            });
        }
        stickFeedItemArr = stickFeedItemArr.map((mapItem, mapIndex) => ({
            id: mapItem.id,
            type: mapItem.type,
            title: mapItem.title,
            url: mapItem.url,
            wwwUrl: mapItem.wwwUrl,
            stick: mapItem.stick,
            source: mapItem.source,
            base62Id: mapItem.base62Id,
            commentUrl: mapItem.commentUrl,
            noPrefixKind: mapItem.noPrefixKind,
            secretCallUp: mapItem.secretCallUp,
            kind: mapItem.kind,
            index: mapIndex,
            ...mapItem,
        }));

        stickFeedAllArr.push(stickFeedItemArr);
    });

    return stickFeedAllArr;
};

const feedRandomDataHandle = (data, countLength, index) => {
    // const endNum = countLength - 1;
    // let num = 0;

    // if (storage.isfastmode()) {
    //     num = Math.floor(Math.random() * endNum);
    // } else {
    //     let selfNum = storage.getlocal(`stickNum${index}`);

    //     if (selfNum === null) {
    //         storage.setlocal(`stickNum${index}`, 0);
    //         selfNum = 0;
    //     } else {
    //         selfNum = parseInt(selfNum) + 1;
    //         if (selfNum > endNum) {
    //             selfNum = 0;
    //         }
    //         storage.setlocal(`stickNum${index}`, selfNum);
    //     }

    //     num = selfNum;
    // }
    const num = Math.floor(Math.random() * countLength);

    return data[num];
};
const newStickFeedDataHandle = stickFeed => {
    if (!stickFeed || stickFeed.length === 0) return stickFeed;
    const stickFeedDataFormat = stickFeedDataFormatHandle(stickFeed);
    const newStickFeedData = [];

    stickFeedDataFormat.forEach((item, index) => {
        let feedRandomData = [];

        if (item.length === 1) {
            feedRandomData = item[0];
        }

        if (item.length > 1) {
            feedRandomData = feedRandomDataHandle(item, item.length, index);
        }

        newStickFeedData.push(feedRandomData);
    });

    return newStickFeedData;
};

export { newStickFeedDataHandle };
