import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { getCustomDataList } from '../../../../../services/api';
import Observer from '@researchgate/react-intersection-observer';
import styles from './index.css';
import Module from '../../module';

class Buddhism extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        dataListArr: [],
    };

    async getDataListFn() {
        try {
            const dataListArr = await getCustomDataList('17007_719_37');

            this.setState({
                dataListArr,
            });
        } catch (e) {
            console.error(e);
        }
    }

    handleChange = (event, unobserve) => {
        const { content } = this.props;
        const { isAsync } = content;

        if (event.isIntersecting) {
            unobserve();
            if (isAsync) {
                this.getDataListFn();
            }
        }
    };

    render() {
        const { content } = this.props;
        const { dataListArr } = this.state;
        const { buddhismMd, buddhismSlide, buddhismFeed, buddhismAd, isAsync } = content;

        const normalTabsData = buddhismMd;
        const viewMoreLinkData = (buddhismMd && buddhismMd.moreTh) || '';
        const newsStreamData = isAsync ? dataListArr : buddhismFeed;
        const mdBannerData = buddhismSlide;

        if (normalTabsData) {
            normalTabsData.chipConfig = {
                id: '50157',
                type: 'struct',
                title: '佛教子导航数据',
                groupName: 'content',
                translate: 'handleHomeSocietyMdInfo',
            };
        }
        if (mdBannerData) {
            mdBannerData.chipConfig = {
                id: '75189',
                type: 'recommend',
                title: '佛教焦点图数据',
                groupName: 'content',
                translate: 'handleHomeSocietySlideInfo',
            };
        }
        const newsStreamChipConfig = {
            id: '75213',
            type: 'recommend',
            title: '佛教信息流数据',
            groupName: 'content',
            translate: 'handleHomeBuddhismFeedInfo',
        };
        const moduleData = {
            normalTabsData,
            feedAd: buddhismAd,
            mdBannerData,
            newsStreamChipConfig,
            newsStreamData,
            viewMoreLinkData,
        };

        return (
            <Observer onChange={this.handleChange}>
                <section className={styles.buddhism}>
                    <Module content={moduleData} />
                </section>
            </Observer>
        );
    }
}

export default errorBoundary(Buddhism);
