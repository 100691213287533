import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import BrowserGuideLayer from '@ifeng-fe/three_terminal/es/mobile/browserGuideLayer/browserGuideLayer';

class BrowserGuide extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content = {} } = this.props;
        const callAppConfig = {
            docType: 'awake',
            kind: 'inter_sflahuo05',
            noKindFrom: 1,
            downloadAd: 'https://statistics.appstore.ifeng.com/index.php/api/godownload?app_id=4&d_ch=8835',
        };
        const clickCallAppConfig = {
            method: 'get',
            value: 'turnon',
        };
        const clickContinueConfig = {
            method: 'get',
            value: 'carryon',
        };
        const browserGuideLayer = content.browserGuideLayer || {};

        return (
            browserGuideLayer.ifengHome === '1' && (
                <BrowserGuideLayer
                    storeInfo={'Home'}
                    browserConfig={browserGuideLayer}
                    callAppConfig={callAppConfig}
                    clickCallAppConfig={clickCallAppConfig}
                    clickContinueConfig={clickContinueConfig}
                />
            )
        );
    }
}

export default errorBoundary(BrowserGuide);
