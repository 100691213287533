import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';

class Nav extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        moreNum: PropTypes.number,
        colNum: PropTypes.number,
        showNum: PropTypes.number,
        showMoreArrow: PropTypes.bool,
    };

    state = {
        isClickMore: false,
        fontSizeStyle: {},
    };

    componentDidMount() {
        window.scaledWatch.add(scaledData => {
            const scaled = scaledData?.scaled;
            const navMaxScaled = 1.13;

            if (scaled && scaled > navMaxScaled) {
                const navMaxFont = 0.4 / navMaxScaled;
                const diff = scaled - navMaxScaled;

                this.setState({
                    fontSizeStyle: {
                        fontSize: `${0.4 / ((diff * navMaxFont) / 0.4 + 1)}rem`,
                        lineHeight: `${0.986667 / ((diff * navMaxFont) / 0.4 + 1)}rem`,
                    },
                });
            } else {
                this.setState({
                    fontSizeStyle: {},
                });
            }
        });
    }

    loadMore = () => {
        const { isClickMore } = this.state;

        this.setState({
            isClickMore: !isClickMore,
        });
    };
    /**
     * 渲染网页布局
     */
    render() {
        const { content, moreNum = 34, showNum = 13, colNum = 7, showMoreArrow = true } = this.props;
        const { isClickMore, fontSizeStyle } = this.state;
        const itemWidth = `${Math.floor((100 / colNum) * 100) / 100}%`;
        const navItem = content.slice(0, isClickMore ? moreNum : showNum).map((item, index) => (
            <li className={styles.item} key={index} style={{ width: content.length - 1 === index ? itemWidth : itemWidth, ...fontSizeStyle }}>
                <a href={item.url} className={styles.link}>
                    {item.title}
                    {item.redHot && <span className={styles.redHot} />}
                </a>
            </li>
        ));

        return (
            <nav className={styles.nav}>
                <ul className={styles.navbox}>
                    {navItem}
                    {showMoreArrow ? <li onClick={this.loadMore} className={isClickMore ? `${styles.more} ${styles.turn} ` : styles.more} /> : null}
                </ul>
            </nav>
        );
    }
}

export default Nav;
