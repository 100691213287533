import React from 'react';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import { cookie } from '@ifeng-fe/ui_base';

class AddDesktop extends React.PureComponent {
    state = {
        hasDesktopFix: false,
    };

    componentDidMount() {
        this.isShowDesktopFixFn();
    }

    isShowDesktopFixFn() {
        const UA = navigator.userAgent;

        if (!cookie.get('iphoneSafari') && UA.match(/iPhone/gi) && UA.match(/Safari/gi)) {
            if (UA.match(/QQBrowser/) || UA.match(/UCBrowser/)) return false;
            this.setState({
                hasDesktopFix: true,
            });
            setTimeout(() => {
                this.setState({
                    hasDesktopFix: false,
                });
            }, 5000);

            cookie.set('iphoneSafari', 1, '', '', 24 * 60 * 60 * 1000);
        }
    }
    closeFixed = () => {
        this.setState({
            hasDesktopFix: false,
        });
    };

    render() {
        const { hasDesktopFix } = this.state;

        return hasDesktopFix && <section onClick={this.closeFixed} className={styles.desktopFix} />;
    }
}

export default errorBoundary(AddDesktop);
