import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Observer from '@researchgate/react-intersection-observer';
import { getHouseData } from '../../../../../services/api';
import { formatImageWebpAuto } from '@ifeng-fe/public_method';
import Module from '../../module';
import styles from './index.css';

class House extends React.PureComponent {
    static propTypes = {
        ad: PropTypes.object,
        isAsync: PropTypes.bool,
    };
    state = {
        moduleData: {},
    };

    componentDidMount() {
        const { isAsync } = this.props;

        if (!isAsync) {
            this.getHouseAPI();
        }
    }

    async getHouseAPI() {
        try {
            const dataCb = await getHouseData();
            const { ad } = this.props;

            if (!dataCb) return '';
            const normalTabsData = {
                title: dataCb.moduleTh.title,
                url: dataCb.moduleTh.url,
                subTit: dataCb.moduleTh.subTit,
            };
            const indexSlidesData = dataCb.banner.slice(0, 2).map((item, index) => ({
                id: index,
                title: item.title || '',
                src: formatImageWebpAuto(item.pic_url, 698, 350) || '',
                url: item.url || '',
            }));

            if (normalTabsData) {
                normalTabsData.chipConfig = {
                    id: '50152',
                    type: 'struct',
                    title: '房产子导航数据',
                    groupName: 'content',
                    translate: 'handleHomeHouseMdInfo',
                };
            }

            const newsStreamData = dataCb.InformationFlow.map((item, index) => ({
                id: index,
                type: item.new_type === 2 ? 'slide' : 'article',
                title: item.title,
                newStyle: 'titleimg',
                url: item.url,
                thumbnails: {
                    image: [
                        {
                            bytes: 277,
                            width: 340,
                            url: formatImageWebpAuto(item.pic_url, 340, 188),
                            height: 188,
                        },
                    ],
                },
                source: item.source || '',
                commentUrl: item.commentUrl || '',
                picNum: item.new_type === 2 ? item.pic_num : 0,
            }));
            const viewMoreLinkData = {
                title: dataCb.moreTh.title,
                link: dataCb.moreTh.url,
            };
            const moduleData = {
                normalTabsData,
                feedAd: ad,
                indexSlidesData,
                newsStreamData,
                viewMoreLinkData,
            };

            this.setState({
                moduleData,
            });
        } catch (e) {
            console.error(e);
        }
    }

    handleChange = (event, unobserve) => {
        const { isAsync } = this.props;

        if (event.isIntersecting) {
            unobserve();
            if (isAsync) {
                this.getHouseAPI();
            }
        }
    };

    render() {
        const { moduleData } = this.state;

        return (
            <Observer onChange={this.handleChange}>
                <section className={styles.house}>
                    <Module content={moduleData} />
                </section>
            </Observer>
        );
    }
}

export default errorBoundary(House);
