import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { cookie } from '@ifeng-fe/ui_base';
import styles from './index.css';

class BookRack extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;
        let isShowBookrack = false;

        if (content && content.isOpen && cookie.get('lastRead') && cookie.get('city') !== '010') {
            isShowBookrack = true;
        }

        return (
            isShowBookrack && (
                <a href={content.goUrl} className={styles.bookrack}>
                    <img src={content.imgUrl} className={styles.bookrackImg} />
                </a>
            )
        );
    }
}

export default errorBoundary(BookRack);
